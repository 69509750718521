<template>
    <div class="right-content mx-auto my-auto">
        <ValidationObserver v-slot="{ passes }">
            <div class="header pb-3 text-center">
                <p class="lead">Recover my password</p>
            </div>
            <div class="body">
                <form class="form-auth-small" @submit.prevent="passes(forgotSubmit)" >
                    <ValidationProvider name="email" rules="required|email" v-slot="{ errors}">
                        <div class="form-group" :class="{'errors': errors.length && !forgotformdata.email || errors[0],'success':forgotformdata.email && !errors[0], 'mb-0':apiErrorCode == 'E0006-001'}">
                            <span class="float-label">
                                <input type="email" v-model="forgotformdata.email" class="form-control" name="email" id="forgot-email">
                                <label for="forgot-email" class="control-label">Email*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !forgotformdata.email || errors[0]"></i>
                                <i class="fa fa-check" v-if="forgotformdata.email && !errors[0]"></i>
                            </span>
                            <span class="text-danger" v-if="errors[0]">Please enter a valid Email Address</span>
                        </div>
                    </ValidationProvider>
                    <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'E0006-001'">{{apiErrorMessage}}</span>
                    <!-- <b-button type="submit" block variant="primary" size="lg">RESET PASSWORD</b-button> -->
                    <button-spinner class="btn btn-primary btn-lg btn-block"  v-bind="{isLoading}" :disabled="isLoading"> RESET PASSWORD</button-spinner>
                    <div class="bottom">
                        <span class="helper-text">Know your password? <router-link to="/page-login">Login</router-link></span>
                    </div>
                </form>
            </div>
        </ValidationObserver>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import VueButtonSpinner from 'vue-button-spinner';
import shared from "@/shared.js";


export default {
    name:'ForgotPasswordComponent',
    components: {
        ValidationObserver,
        ValidationProvider,
        'button-spinner':VueButtonSpinner,

    },methods: {
        forgotSubmit() {
            let forgotPasswordData = {
                email: this.forgotformdata.email
            }
            this.isLoading = true;
            this.$http.post(this.$baseurl+'business/auth/forgotPassword', forgotPasswordData)
            .then((res) => {
                //Perform Success Action
                // console.log(res);
                this.isLoading = false;
                if(res.data.code == '0006-000') {
                    this.$store.dispatch('resetPassword/addEmail', forgotPasswordData);
                    let resMessage = 'Mail has been sent'; //res.data.message; //'Please provide reset passcode which has receive by mail'
                   // this.$swal('Success!', resMessage, 'success', 'OK');
                    shared.toastrSuccess(resMessage);
                    this.$router.push({ path: '/reset-password' })
                } else {
                    //this.$swal('Error!', res.message, 'error', 'Close');
                    shared.toastrError(res.message);
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                this.apiErrorCode = error.response.data.code;
                this.apiErrorMessage = error.response.data.message;
                //console.log('forgotPassword',error.response.data.code);
                this.isLoading = false;

            });
             //this.$router.push({ path: '/authentication/page-otp' })
        },
    },
    data(){
        
        return{
            forgotformdata: {
                email: "",
            },
            isLoading: false,
            apiErrorCode : '',
            apiErrorMessage : ''

        }
        

    }
}
</script>
