<template>
    <div class="container-fluid">
        <div class="block-header">
                <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card">
                    <div class="header d-flex justify-content-between align-items-center">
                        <h2>User Management</h2>
                        <router-link to="#" class="btn btn-primary">Add new user</router-link>
                        <!-- /user/add -->
                    </div>
                    <div class="body pt-0">
                        <b-table responsive table-class="mb-0 table-custom border" head-variant="light" :items="items"
                            :fields="fields" :current-page="currentPage" :per-page="perPage" :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                            <template #head()="{ label, field: { key } }">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>
                            </template>
                            <template #cell(role)="data">
                                <p class="function-badge badge-group-table mb-0">
                                    <span class="badge ml-0" :class="data.value.class">{{ data.value.name }}</span>
                                </p>
                            </template>
                            <template #cell(createdat)="data">
                                <strong>{{ data.value.date }}</strong>
                                <small class="text-grey d-block">{{ data.value.time }}</small>
                            </template> 
                            <template #cell(status)="data">
                                <p class="function-badge badge-group-table mb-0">
                                    <span class="badge ml-0" :class="data.value.class">{{ data.value.name }}</span>
                                </p>
                            </template>
                            <template #cell(action)>
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item>Edit</b-dropdown-item>
                                    <b-dropdown-item>Delete</b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
export default {
    name:'ListComponent',
    components: {
        BreadCrumb
    },
    methods: {
        showAlert() {
            // Use sweetalert2
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                    )
                }
            })
        }
    },data(){
        return {
            items: [
                {
                    name:'John Archt',
                    role:{name:'Admin',class:'badge-success'},
                    username:'john@test.com',
                    createdat:{ date: '16-Nov-2022', time:'06:50pm GMT' },
                    status:{name:'Active',class:'badge-success'},
                    action:''
                },
            ],

            fields: [
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'role', label: 'Role',sortable: true,thClass:'th_sortfix'},
                { key: 'username', label: 'Username',sortable: true,thClass:'th_sortfix'},
                { key: 'createdat', label: 'Created at',sortable: true,thClass:'th_sortfix'},
                { key: 'status', label: 'Status',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'Action',thClass:'th_sortfix'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 50,
            pageOptions: [50, 100, 150, 200, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
        }
    }

}
</script>
<style scoped>

</style>