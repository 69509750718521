import { render, staticRenderFns } from "./EditCustomerComponent.vue?vue&type=template&id=47f3cc62&"
import script from "./EditCustomerComponent.vue?vue&type=script&lang=js&"
export * from "./EditCustomerComponent.vue?vue&type=script&lang=js&"
import style0 from "vue-tel-input/dist/vue-tel-input.css?vue&type=style&index=0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports