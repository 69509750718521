<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <ValidationObserver v-slot="{ passes }">
                        <div class="header">
                            <h2>Edit Beneficiary</h2>
                        </div>
                        <div class="body">
                            <form @submit.prevent="passes(userSubmitData)" id="basic-form">
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">
                                            <span class="float-label">
                                                <input type="text" value="10840" class="form-control" disabled>
                                                <label class="control-label">Remitter Id With Remit One</label>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                        <ValidationProvider name="first_name" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !user.first_name,'success':user.first_name}">
                                                <span class="float-label">
                                                    <input type="text" v-model="user.first_name" class="form-control" id="first_name" placeholder="First Name*">
                                                    <label for="first_name" class="control-label">First Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !user.first_name"></i>
                                                    <i class="fa fa-check" v-if="user.first_name"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <div class="form-group">
                                            <span class="float-label">
                                                <input type="text" class="form-control" id="middle_name" v-model="user.middle_name" placeholder="Middle Name">
                                                <label for="middle_name" class="control-label">Middle Name</label>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                        <ValidationProvider name="last_name" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !user.last_name,'success':user.last_name}">
                                                <span class="float-label">
                                                    <input type="text" v-model="user.last_name" class="form-control" id="last_name" placeholder="Last Name*">
                                                    <label for="last_name" class="control-label">Last Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !user.last_name"></i>
                                                    <i class="fa fa-check" v-if="user.last_name"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>

                                        <ValidationProvider name="relationship" rules="required" v-slot="{ errors }">
                                            <div class="form-group form-select single-multiselect select-option-custom" :class="{'errors': errors[0],'success':user.relationship && !errors[0]}">
                                                <span class="float-label">
                                                <label for="relationship">Select Relationship*</label>
                                                    <multiselect
                                                        placeholder=""
                                                        v-model="user.relationship"
                                                        :show-labels="false" 
                                                        label="name"
                                                        :options="relationship_options">
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.relationship && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <div class="form-group" v-if="user.relationship && user.relationship.value === 'Other'">
                                            <span class="float-label">
                                                <input type="text" class="form-control" id="other_name" v-model="user.otherrelation" placeholder="Please Specify Relationship">
                                                <label for="other_name" class="control-label">Specify Relationship</label>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                        <div class="form-group datepicker">
                                            <span class="float-label">
                                                <b-input-group>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">Date of Birth</span>
                                                        <span class="input-group-text" @click="$refs.birthdate.showCalendar()"><i class="icon-calendar"></i></span>
                                                    </div>
                                                    <datepicker class="form-control" ref="birthdate" v-model="user.dob" :bootstrap-styling="false" placeholder="" :typeable="true"></datepicker>
                                                </b-input-group>    
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <ValidationProvider name="select_country" rules="required" v-slot="{ errors}">
                                            <div class="country-dropdown">
                                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.country && user.country.length != 0  && !errors[0]}">
                                                    <span class="float-label">
                                                    <label for="Country">Select Country*</label>
                                                        <multiselect
                                                            placeholder=""
                                                            v-model="user.country" 
                                                            :show-labels="false" 
                                                            :options="countrys_options" 
                                                            :searchable="false" 
                                                            label="name" 
                                                            track-by="name"
                                                            @select="setPrimaryNumberCountryCode">
                                                            <template slot="option" slot-scope="props">
                                                                <span :class="props.option.flagClass"></span>
                                                                <div class="option__desc">
                                                                    <span class="option__title">{{ props.option.name }}</span>
                                                                </div>
                                                            </template>
                                                        </multiselect> 
                                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                        <i class="fa fa-check" v-if="user.country && user.country.length != 0 && !errors[0]"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                        <div class="form-group">
                                            <span class="float-label">
                                                <textarea class="form-control" id="address1" v-model="user.address1" placeholder="Address Line 1"></textarea>
                                                <label for="address1" class="control-label">Address Line 1</label>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                        <div class="form-group">
                                            <span class="float-label">
                                                <textarea class="form-control" id="address2" v-model="user.address2" placeholder="Address Line 2"></textarea>
                                                <label for="address2" class="control-label">Address Line 2</label>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                        <ValidationProvider name="city" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !user.city,'success':user.city}">
                                                <span class="float-label">
                                                    <input type="text" v-model="user.city" class="form-control" id="city" placeholder="First Name*">
                                                    <label for="city" class="control-label">City*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !user.city"></i>
                                                    <i class="fa fa-check" v-if="user.city"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <div class="form-group">
                                            <span class="float-label">
                                                <input type="text" class="form-control" id="zipcode" v-model="user.zipcode" placeholder="Zip Code">
                                                <label for="zipcode" class="control-label">Zip Code</label>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                        <!-- Country Code and Mobile Number -->
                                        <div class="form-group" :class="{'errors': mobileInputError == false,'success':user.mobile_number && mobileInputError}">
                                            <div class="float-label" v-if="primaryMobileNumber.defaultCountry">
                                                <label for="primary_number" class="control-label">Mobile*</label>
                                                <vue-tel-input
                                                    v-model="user.mobile_number"
                                                    v-bind="primaryMobileNumber"
                                                    @validate ="mobileInputValidate"
                                                    ref="telInput"
                                                    @keydown.native="restrictSpecialCharacter($event)">
                                                    <template v-slot:arrow-icon>
                                                        <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                                    </template>
                                                </vue-tel-input>
                                                <div class="line"></div>
                                                <i class="fa fa-times" v-if="mobileInputError == false"></i>
                                                <i class="fa fa-check" v-if="mobileInputError && user.mobile_number"></i>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <span class="float-label">
                                                <input type="text" class="form-control" id="alternate_mobile" v-model="user.alternate_mobile" placeholder="Alternate Mobile">
                                                <label for="alternate_mobile" class="control-label">Alternate Mobile</label>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-12">
                                        <h5>Payout Options*</h5>
                                        <div class="fancy-checkbox">
                                            <label><input type="checkbox" v-model="user.payout_options" id="bankDeposit" value="BankDeposit"><span>Fast Bank Deposit</span></label>
                                        </div>
                                        <div class="fancy-checkbox">
                                            <label><input type="checkbox" v-model="user.payout_options" id="cashCollection" value="CashCollection" checked><span>Cash Collection</span></label>
                                        </div>
                                        <div class="fancy-checkbox">
                                            <label><input type="checkbox" v-model="user.payout_options" id="mobileMOney" value="MobileMoney"><span>Mobile Money</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="user.payout_options.includes('BankDeposit')">
                                    <div class="col-md-12 col-12"><h5>Direct Bank deposit</h5></div>
                                    <div class="col-md-6 col-12">   
                                        <div class="form-group">
                                            <span class="float-label">
                                                <input type="text" class="form-control" v-model="user.bank" placeholder="Bank">
                                                <label for="bank" class="control-label">Bank</label>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                        <div class="form-group">
                                            <span class="float-label">
                                                <input type="text" class="form-control" v-model="user.branch" placeholder="Branch">
                                                <label for="branch" class="control-label">Branch</label>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                        <div class="form-group">
                                            <span class="float-label">
                                                <input type="text" class="form-control" v-model="user.account" placeholder="Account Number">
                                                <label for="account_number" class="control-label">Account Number</label>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">
                                            <span class="float-label">
                                                <input type="text" class="form-control" v-model="user.sortcode" placeholder="Sort Code">
                                                <label for="sortcode" class="control-label">Sort Code</label>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                        <div class="form-group">
                                            <span class="float-label">
                                                <input type="text" class="form-control" v-model="user.swiftcode" placeholder="Swift Code">
                                                <label for="swiftcode" class="control-label">Swift Code</label>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="user.payout_options.includes('CashCollection')">
                                    <div class="col-md-12 col-12">
                                        <h5>Cash Collection</h5>
                                        <p>Your Beneficiary can visit and collect their cash from any branch or outlet of our Partner Institutions listed below. Please note that they will have to take their ID along with them.<br/>Outlets:<br/><b>All Tigo Outlets</b><br/><u>Delivery Time</u><br/>Same Day (processed hourly)<br/><span class="cursor" v-if="isVisible == false" @click="isVisible = !isVisible"><i>Find Out More</i></span></p>
                                        <span v-if="isVisible==true">
                                            <p><b>Collection Outlets</b></p>
                                            <ul class="list">
                                                <li class="mb-2"><a href="https://dev.payangel.com/resources/upload_files/collection_document/Ashanti_,Brong_Ahafo_Western_North_Region.pdf" target="_blank" class="mb-2"><u>Ashanti, Brong Ahafo and Western North Regions</u></a></li>
                                                <li class="mb-2"><a href="https://dev.payangel.com/resources/upload_files/collection_document/Eastern_Region,_Volta_Region_Tema.pdf" target="_blank"><u>Eastern Region, Volta Region and Tema</u></a></li>
                                                <li class="mb-2"><a href="https://dev.payangel.com/resources/upload_files/collection_document/Greater_Accra_Region.pdf" target="_blank"><u>Greater Accra Region</u></a></li>
                                                <li class="mb-2"><a href="https://dev.payangel.com/resources/upload_files/collection_document/Northern,_Upper_East_Upper_West_Region.pdf" target="_blank"><u>Northern , Upper East and Upper West Regions</u></a></li>
                                                <li><a href="https://dev.payangel.com/resources/upload_files/collection_document/Western_South,_Central_and_Parts_of_Eastern_Regions.pdf" target="_blank"><u>Western South, Central and Part of Eastern Regions</u></a></li>
                                            </ul>
                                            <p class="cursor" @click="isVisible = !isVisible"><i>View Less</i></p>
                                        </span>
                                    </div>
                                </div>
                                <div class="row" v-if="user.payout_options.includes('MobileMoney')">
                                    <div class="col-md-12 col-12">
                                        <h5>Mobile Money</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis</p>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="form-group form-select single-multiselect select-option-custom">
                                            <span class="float-label">
                                            <label for="network">Select Telco Name</label>
                                                <multiselect
                                                    placeholder=""
                                                    v-model="user.network"
                                                    :show-labels="false" 
                                                    :options="network_options">
                                                </multiselect>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">
                                            <span class="float-label">
                                                <input type="text" class="form-control" id="telco_number" v-model="user.telcoNUmber" placeholder="Mobile Number">
                                                <label for="telco_number" class="control-label">Mobile Number</label>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-md-row justify-content-center">
                                    <b-button variant="secondary btn-mw" size="lg" @click="cancel()">Cancel</b-button>
                                    <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Submit</b-button>
                                </div>
                            </form>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const { VueTelInput } = require('vue-tel-input')
import parsePhoneNumber from 'libphonenumber-js';
const LoaderComponent = require("@/components/admin/loader/LoaderComponent.vue").default;
export default {
    name: "EditBeneficiaryComponent",
    components: {
        ValidationObserver,
        ValidationProvider,
        VueTelInput,
        Multiselect: () => import('vue-multiselect'),
        BreadCrumb : () => import('@/components/BreadCrumb.vue'),
        Datepicker: () => import('vuejs-datepicker'),
        "main-loader": LoaderComponent,
    },
    data: () => ({
        isAllChecked:false,
        isVisible:false,
        relationship_options:[
            { value: 'Son', name:'Son'},
            { value: 'Daughter', name:'Daughter'},
            { value: 'Brother', name:'Brother'},
            { value: 'Sister', name:'Sister'},
            { value: 'Parents', name:'Parents'},
            { value: 'Spouse', name:'Spouse'},
            { value: 'Family', name:'Family - Other'},
            { value: 'Friend', name:'Friend'},
            { value: 'Colleague', name:'Colleague'},
            { value: 'Business Partner', name:'Business Partner'},
            { value: 'Other', name:'Other - please specify'},
        ],
        countrys_options: [],

        network_options: ['africell', 'airtel', 'AIRTEL', 'GoMoney', 'Mobicash', 'MOOV', 'MPESA', 'MTN', 'mtn', 'ORANGE', 'orange', 'Paga','telecome', 'TIGO', 'VODAFONE'],
        // payoutOption: [
        //     { "name": "Fast Bank Deposit","value":"BankDeposit" },
        //     { "name": "Cash Collection","value":"CashCollection" },
        //     { "name": "Mobile Money","value":"MobileMoney" }
        // ],

        user: {
            first_name:'',
            middle_name:'',
            last_name:'',
            relationship: null,
            otherrelation:'',
            dob:'',
            country: null,
            address1:'',
            address2:'',
            city:'',
            zipcode:'',
            mobile_number:'',
            alternate_mobile:'',
            payout_options: [],
            bank:'',
            branch:'',
            account:'',
            sortcode:'',
            swiftcode:'',
            network:'',
            telcoNUmber:''
        },
        mobileInputError:'',
        primaryMobileNumber:{
            mode: "auto",
            defaultCountry:"",
            autoFormat:false,
            dropdownOptions:{
                showFlags: true,
                disabled:true,
                showDialCodeInSelection:true
            }, 
        },
    }),
    methods: {
        userSubmitData() {
            if(typeof(this.mobileInputError) == 'undefined'){
                this.mobileInputError = false
            }
            if(this.mobileInputError){
                console.log('submit',this.user.payout_options);
            }
        },

        cancel(){
            this.$router.push({ path: '/beneficiary/list' })
        },

        restrictSpecialCharacter(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        },

        setPrimaryNumberCountryCode(defaultCountry) {
            this.$refs.telInput.choose(defaultCountry.isocode);
            // sessionStorage.setItem('countryCode',defaultCountry.isocode);
        },

        mobileInputValidate(phoneObject){
            this.mobileInputError = phoneObject.valid
        },
        
        displayText(count){
            if (count === 0){
                return ''
            }else if (count === 1){
                return this.user.country[0].name
            }else if(count == this.countrys_options.length){
                this.isAllChecked = true;
                return count + ' Countrys Selected.'
            }else {
                this.isAllChecked = false;
                return  count + ' Countrys Selected.'
            }
        },

        selectAllChanged() {
            if (this.isAllSelected) {
                this.isAllChecked = false;
                this.user.country = [];
                this.countrys_options.map((data)=>{
                    data.isChecked = false
                });
            }else{
                this.isAllChecked = true;
                this.user.country = this.countrys_options.slice();
                this.countrys_options.map((data)=>{
                    data.isChecked = true
                });
            }
        },

        isCheckedInput (actionName) {
            this.countrys_options[actionName.id].isChecked = !this.countrys_options[actionName.id].isChecked
        },

        async allCountry(){
            try {
                const response = await this.$http.get(this.$baseurl+"countries");
                //JSON responses are automatically parsed.
                response.data.map((item) => {
                    let countryData = {
                        id:item.countryid, 
                        isocode: item.isocode, 
                        code: item.mobilecountrycode, 
                        name: item.countryname, 
                        flagClass:"flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
                    }
                    this.countrys_options.push(countryData);
                })
            } catch (error) {
                console.log(error);
                // shared.toastrError(error);
            }
        },

        async beneficiaryDetail(beneficiaryid){
            try {
                this.$refs.loader.show();
                const response = await this.$http.get(
                    this.$apiV2+"user/beneficiary/?beneficiary_id="+beneficiaryid,
                    {
                        // headers: {
                        //     'Authorization': 'Basic YmRkMTc5NTY5MmQ1NTBiMWIwY2M0YmUzZmJkN2MyMTY6ZTczYTdhNDhjNDYzNDM5Nzg1MTUyNTI4MWE4NzdkMWY='
                        // }
                    }
                );
                // console.log(response.data);
                if(response.data.code == '0131-000'){
                    const beneficiarydetails = response.data.data[0];
                    const businessDetails = beneficiarydetails.business;
                    const addressDetail = beneficiarydetails.address;

                    let countryofresidence = null;
                    let mobilenumber = '';
                    this.countrys_options.forEach(element => {
                        if(element.id == businessDetails.countryofresidence){
                            countryofresidence = {
                                id:element.id, 
                                isocode: element.isocode, 
                                code: element.code, 
                                name: element.name, 
                                flagClass:"flag-icon flag-icon-"+element.isocode.toLowerCase()+" fi-xl fi-round",
                            };

                            this.primaryMobileNumber = {
                                mode: "auto",
                                defaultCountry:element.isocode,
                                autoFormat:false,
                                dropdownOptions:{
                                    showFlags: true,
                                    disabled:true,
                                    showDialCodeInSelection:true
                                }, 
                            };
                            
                            if(beneficiarydetails.mobile){
                                const phoneNumber = parsePhoneNumber(beneficiarydetails.mobile, element.isocode);
                                mobilenumber = phoneNumber.nationalNumber;
                            }
                        }
                    });

                    this.user.first_name=beneficiarydetails.firstname;
                    this.user.middle_name=beneficiarydetails.middlename;
                    this.user.last_name=beneficiarydetails.lastname;
                    this.user.relationship= beneficiarydetails.relationship;
                    this.user.otherrelation='';
                    this.user.dob=beneficiarydetails.dob;
                    this.user.country= countryofresidence;
                    this.user.address1=addressDetail.addressline1 ? addressDetail.addressline1 : '';
                    this.user.address2=addressDetail.addressline2 ? addressDetail.addressline2 : '';
                    this.user.city=addressDetail.city ? addressDetail.city : '';
                    this.user.zipcode=addressDetail.postalcode ? addressDetail.postalcode : '';
                    this.user.mobile_number=mobilenumber;
                    this.user.alternate_mobile=beneficiarydetails.phone;
                    this.user.payout_options= [],
                    this.user.bank='';
                    this.user.branch='';
                    this.user.account='';
                    this.user.sortcode='';
                    this.user.swiftcode='';
                    this.user.network='';
                    this.user.telcoNUmber='';
                
                    this.$refs.loader.hide();
                }
            } catch (error) {
                console.log(error);
                this.$refs.loader.hide();
            }
        }
    },computed: {
        isAllSelected: function() {
            return  (this.user.country.length === this.countrys_options.length)
        }
    },mounted(){
        this.allCountry();
        if(sessionStorage.getItem('beneficiaryID')){
            this.beneficiaryDetail(sessionStorage.getItem('beneficiaryID'));
        }
    }
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style scoped>
.cursor{
    cursor: pointer;
}
ul.list {list-style-type: none;}
</style>