<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card card-top-line">
                    <div class="header">
                        <h2>Partner List</h2>
                    </div>
                    <div class="body pt-0">
                        <div class="chart-top-action d-lg-flex justify-content-between py-3">
                            <div class="d-md-flex justify-content-between">
                                <div class="mr-0 mr-md-3 action-top-search">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search partner" aria-label="Search partner" aria-describedby="search-partner" v-model="searchText" v-on:keyup.enter="refreshPartnerTable">
                                        <div class="input-group-append" @click="refreshPartnerTable()">
                                            <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-md-flex justify-content-between mt-lg-0 mt-md-3 mt-0">
                                <div class="payment-dropdown d-flex flex-row-reverse ml-lg-3 mt-3 mt-sm-0">
                                    <b-button size="lg" variant="primary btn-mw" @click="addPartner()">Add Partner <i class="fa fa-plus-square"></i></b-button>
                                </div>
                            </div>
                        </div>

                        <b-table 
                            ref="partnertable"
                            responsive
                            hover 
                            table-class="js-basic-example table-custom mb-0 border"
                            head-variant="light"
                            :items="items" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(action)>
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item>Active</b-dropdown-item>
                                    <b-dropdown-item @click="editpartner()">Edit</b-dropdown-item>
                                    <b-dropdown-item @click="showAlert">Delete</b-dropdown-item>
                                    <b-dropdown-item>Partner Login</b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 align-items-center justify-content-between table-pagination" :class="totalRows > 0?' d-flex':'d-none'" v-if="totalRows > 10">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" v-model="currentPageJump" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const { toastFunction } =  require("@/plugins/toast")
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
const { sweetAlertFunction } =  require("@/plugins/sweetalert2")

export default {
    name:'PartnerListComponent',
    components: {
        BreadCrumb,
        'main-loader':LoaderComponent,
    },data(){
        return{ 
            options: [
                { value: 'pending', text:'Pending' },
                { value: 'approved', text: 'Approved' },
                { value: 'rejected', text: 'Rejected' },
            ],

            items:[
                {country:'United Kingdom',name:'Akosua Nunoo',email:'akosuatemaah1236@hotmail.com',mobile:'+447799691752',regdate:'2019-07-09 15:04:00',compname:'ABURI GIRLS ...',action:''},
                {country:'Ghana',name:'Antonio Bortoletto',email:'abortoletto@f2b.com.br',mobile:'+233263456785',regdate:'2019-07-09 15:04:00',compname:'ALEXpay - Allied ...',action:''},
                {country:'United Kingdom',name:'Akosua Nunoo',email:'akosuatemaah1236@hotmail.com',mobile:'+447799691752',regdate:'2019-07-09 15:04:00',compname:'ABURI GIRLS ...',action:''},
                {country:'Ghana',name:'Antonio Bortoletto',email:'abortoletto@f2b.com.br',mobile:'+233263456785',regdate:'2019-07-09 15:04:00',compname:'ALEXpay - Allied ...',action:''},
            ],
           
            fields: [
                { key: 'country', label: 'Country',sortable: true,thClass:'th_sortfix'},
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'email', label: 'Email',sortable: true,thClass:'th_sortfix'},
                { key: 'mobile', label: 'Mobile',sortable: true,thClass:'th_sortfix'},
                { key: 'regdate', label: 'Reg. Date/Time',sortable: true,thClass:'th_sortfix'},
                { key: 'compname', label: 'Company Name'},
                { key: 'action', label: 'Action'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            totalRows: 0,
            currentPage: 1,
            currentPageJump: 1,
            perPage: 10,
            pageOptions: [10 , 25, 50, { value: 100, text: 100 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            searchText: '',
            filterBy: '',
            filterValue: '',
            condition: ''
        }
    },methods: {
        onChange(event,index) {
          var classVar =''
            return this.items.map((item,i) => {
                if(index == i+1){
                    if(event.target.value == 'accept'){
                        classVar = 'btn-outline-success'
                    }else if(event.target.value == 'cancel'){
                        classVar = 'btn-outline-danger'
                    }
                    item.dropdawn.class = classVar
                }
            });
        },

        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = e.target.value
                }
            }
        },
        
        showAlert() {
            //common options for sweetalert
            var data = {
                icon:'warning',
                title:'Are you sure',
                text:`You won't be able to revert this!`,
                confirmButtonText:'Yes, delete it!',
                showCancelButton:true
            }

            //if action is confirmed
            var confirmActions = {
                loader: this.$refs.loader,
                toastMessage: toastFunction 
            }

            //call function with arg
            sweetAlertFunction.displayAlertPopUp(data,confirmActions);
        },

        refreshPartnerTable() {
            this.$refs.partnertable.refresh();
        },

        resetFilterValue(){
            this.filterBy= '',
            this.filterValue= '',
            this.condition= '',
            this.refreshPartnerTable();
        },

        addPartner(){
            // console.log('Create fund');
            this.$router.push({ path: '/partner/add' })
        },
        editpartner(){
            this.$router.push({ path: '/partner/edit' })
        }
    },mounted() {
        this.totalRows = this.items.length;
    }
}
</script>
