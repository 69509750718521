<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <ValidationObserver v-slot="{ passes }">
                        <div class="header">
                            <h2>Add Partner</h2>
                            <!-- <span style="color:red" class="pull-right"><b>LOGIN TO CUSTOMER ACCOUNT</b></span> -->
                        </div>
                        <div class="body">
                            <form @submit.prevent="passes(userSubmitData)" id="basic-form">
                                <div class="card card-top-line">
                                    <div class="header d-flex align-items-center justify-content-between">
                                        <h2>Personal Information</h2>
                                    </div>
                                    <div class="body">
                                        <div class="edit-form">
                                            <div class="row">
                                                <div class="col-sm-6 col-12">
                                                    <ValidationProvider name="select_country" rules="required" v-slot="{ errors}">
                                                        <div class="country-dropdown">
                                                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':addpartner.country && addpartner.country.length != 0  && !errors[0]}">
                                                                <span class="float-label">
                                                                <label for="Country">Source Country*</label>
                                                                    <multiselect
                                                                        placeholder=""
                                                                        v-model="addpartner.country" 
                                                                        :show-labels="false" 
                                                                        :options="countrys_options" 
                                                                        :searchable="false" 
                                                                        label="name" 
                                                                        track-by="name"
                                                                        @select="setPrimaryNumberCountryCode">
                                                                        <template slot="option" slot-scope="props">
                                                                            <span :class="props.option.flagClass"></span>
                                                                            <div class="option__desc">
                                                                                <span class="option__title">{{ props.option.name }}</span>
                                                                            </div>
                                                                        </template>
                                                                    </multiselect> 
                                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                    <i class="fa fa-check" v-if="addpartner.country && addpartner.country.length != 0 && !errors[0]"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="first_name" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.first_name,'success':addpartner.first_name}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="addpartner.first_name" class="form-control" id="first_name" placeholder="First Name*">
                                                                <label for="first_name" class="control-label">First Name*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.first_name"></i>
                                                                <i class="fa fa-check" v-if="addpartner.first_name"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <div class="form-group">
                                                        <span class="float-label">
                                                            <input type="text" class="form-control" id="middle_name" v-model="addpartner.middle_name" placeholder="Middle Name">
                                                            <label for="middle_name" class="control-label">Middle Name</label>
                                                            <div class="line"></div>
                                                        </span>
                                                    </div>
                                                    <ValidationProvider name="last_name" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.last_name,'success':addpartner.last_name}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="addpartner.last_name" class="form-control" id="last_name" placeholder="Last Name*">
                                                                <label for="last_name" class="control-label">Last Name*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.last_name"></i>
                                                                <i class="fa fa-check" v-if="addpartner.last_name"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="contact_email" rules="required|email" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.contact_email || errors[0],'success':addpartner.contact_email && !errors[0]}">
                                                            <span class="float-label">
                                                                <input type="email" v-model="addpartner.contact_email" class="form-control" name="email1" id="contact_email" placeholder="Email*">
                                                                <label for="contact_email" class="control-label">Email*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.contact_email || errors[0] "></i>
                                                                <i class="fa fa-check" v-if="addpartner.contact_email && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <!-- Country Code and Mobile Number -->
                                                    <div class="form-group" :class="{'errors': mobileInputError == false,'success':addpartner.mobile_number && mobileInputError}">
                                                        <div class="float-label">
                                                            <label for="primary_number" class="control-label">Mobile*</label>
                                                            <vue-tel-input
                                                                v-model="addpartner.mobile_number"
                                                                v-bind="primaryMobileNumber"
                                                                @validate ="mobileInputValidate"
                                                                ref="telInput"
                                                                @keydown.native="restrictSpecialCharacter($event)">
                                                                <template v-slot:arrow-icon>
                                                                    <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                                                </template>
                                                            </vue-tel-input>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="mobileInputError == false"></i>
                                                            <i class="fa fa-check" v-if="mobileInputError && addpartner.mobile_number"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-12">
                                                    <div class="form-group datepicker">
                                                        <span class="float-label">
                                                            <b-input-group>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text">Date of Birth</span>
                                                                    <span class="input-group-text" @click="$refs.birthdate.showCalendar()"><i class="icon-calendar"></i></span>
                                                                </div>
                                                                <datepicker class="form-control" ref="birthdate" v-model="addpartner.dob" :bootstrap-styling="false" placeholder="" :typeable="true"></datepicker>
                                                            </b-input-group>    
                                                        </span>
                                                    </div>
                                                    <ValidationProvider name="postcode" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.address1,'success':addpartner.address1}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="addpartner.address1" class="form-control" id="address1" placeholder="Address1*">
                                                                <label for="address1" class="control-label">Address1*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.address1"></i>
                                                                <i class="fa fa-check" v-if="addpartner.address1"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="postcode" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.postcode,'success':addpartner.postcode}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="addpartner.postcode" class="form-control" id="postcode" placeholder="Postal code*">
                                                                <label for="postcode" class="control-label">Post Code*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.postcode"></i>
                                                                <i class="fa fa-check" v-if="addpartner.postcode"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="countryBirth" rules="required" v-slot="{ errors }">
                                                        <div class="form-group form-select single-multiselect select-option-custom" :class="{'errors': errors[0],'success':addpartner.countryBirth && !errors[0]}">
                                                            <span class="float-label">
                                                            <label for="countryBirth">Country of Birth*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="addpartner.countryBirth"
                                                                    :show-labels="false" 
                                                                    label="name" 
                                                                    :options="countryBirth_options">
                                                                </multiselect>
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="addpartner.countryBirth  && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>

                                                    <ValidationProvider name="password"  rules="required" v-slot="{ errors }" vid="confirmation">
                                                        <div class="form-group set-password" :class="{'errors': (addpartner.passwords != '' && !errorsDisplay) || errors.length > 0,'success': addpartner.passwords && errorsDisplay}">
                                                            <span class="float-label">
                                                                <input :type="addpartner.password_type" @input="password_check" v-model="addpartner.passwords" class="form-control" name="password" id="signup-password" placeholder="Password*">
                                                                <label for="signup-password" class="control-label">Password*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="(addpartner.passwords != ''  && !errorsDisplay) || errors.length > 0"></i>
                                                                <i class="fa fa-check" v-if="addpartner.passwords && errorsDisplay"></i>
                                                                <i class="fa fa-eye" :class="addpartner.password_type == 'password' ? 'grey' : 'success'" aria-hidden="true" @click="showPassword('type_1')"></i>
                                                            </span>
                                                            <div class="password-validation" :class="{'d-none':addpartner.passwords && errorsDisplay}">
                                                                <p :class="{'error':addpartner.passwords.length < 8}"><i :class="addpartner.passwords.length > 7 ? 'fa fa-check' : 'fa fa-times'"></i> Longer than 7 characters</p>
                                                                <p :class="{'error':!has_uppercase}"><i :class="has_uppercase ? 'fa fa-check' : 'fa fa-times'"></i> Has a capital letter</p>
                                                                <p :class="{'error':!has_lowercase}"><i :class="has_lowercase ? 'fa fa-check' : 'fa fa-times'"></i> Has a lowercase letter</p>
                                                                <p :class="{'error':!has_number}"><i :class="has_number ? 'fa fa-check' : 'fa fa-times'"></i> Has a number</p>
                                                                <p :class="{'error':!has_special}"><i :class="has_special ? 'fa fa-check' : 'fa fa-times'"></i> Has a special character</p>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="confirm_password" rules="required|confirmed:confirmation" v-slot="{ errors}">
                                                        <div class="form-group set-password" :class="{'errors': errors.length && !addpartner.confirm_passwords || errors[0],'success':addpartner.confirm_passwords && !errors[0]}">
                                                            <span class="float-label">
                                                                <input :type="addpartner.confirm_type" v-model="addpartner.confirm_passwords" class="form-control" name="confirmation_password" id="confirm_password" placeholder="Confirm Password*">
                                                                <label for="confirm_password" class="control-label">Confirm Password*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.confirm_passwords || errors[0] "></i>
                                                                <i class="fa fa-check" v-if="addpartner.confirm_passwords && !errors[0]"></i>
                                                                <i class="fa fa-eye" :class="addpartner.confirm_type == 'password' ? 'grey' : 'success'" aria-hidden="true" @click="showPassword('type_2')"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-12">
                                                    <div class="fancy-checkbox">
                                                        <label><input type="checkbox" v-model="addpartner.second_personal_detail" id="second_personal_detail" value="second_personal_detail"><span>Second Person Detail</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-6 col-12">
                                                    <div class="form-group">
                                                        <span class="float-label">
                                                            <input type="text" v-model="addpartner.second_first_name" class="form-control" id="second_first_name" placeholder="Second First Name">
                                                            <label for="second_first_name" class="control-label">Second First Name</label>
                                                            <div class="line"></div>
                                                        </span>
                                                    </div>
                                                    <div class="form-group">
                                                        <span class="float-label">
                                                            <input type="text" v-model="addpartner.second_last_name" class="form-control" id="second_last_name" placeholder="Second Last Name">
                                                            <label for="second_last_name" class="control-label">Second Last Name</label>
                                                            <div class="line"></div>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-12">
                                                    <div class="form-group">
                                                        <span class="float-label">
                                                            <input type="email" v-model="addpartner.second_email" class="form-control" id="second_email" placeholder="Second Email">
                                                            <label for="second_email" class="control-label">Second Email</label>
                                                            <div class="line"></div>
                                                        </span>
                                                    </div>
                                                    <div class="form-group">
                                                        <span class="float-label">
                                                            <input type="text" v-model="addpartner.second_mobile" class="form-control" id="second_mobile" placeholder="Second Mobile">
                                                            <label for="second_mobile" class="control-label">Second Mobile(Add country code)</label>
                                                            <div class="line"></div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-top-line">
                                    <div class="header d-flex align-items-center justify-content-between">
                                        <h2>Company Information</h2>
                                    </div>
                                    <div class="body">
                                        <div class="edit-form">
                                            <div class="row">
                                                <div class="col-sm-6 col-12">
                                                    <ValidationProvider name="company_name" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.company_name,'success':addpartner.company_name}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="addpartner.company_name" class="form-control" id="company_name" placeholder="Company Name*">
                                                                <label for="company_name" class="control-label">Company Name*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.company_name"></i>
                                                                <i class="fa fa-check" v-if="addpartner.company_name"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="company_email" rules="required|email" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.company_email || errors[0],'success':addpartner.company_email && !errors[0]}">
                                                            <span class="float-label">
                                                                <input type="email" v-model="addpartner.company_email" class="form-control" name="email1" id="company_email" placeholder="Company Email*">
                                                                <label for="company_email" class="control-label">Company Email*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.company_email || errors[0] "></i>
                                                                <i class="fa fa-check" v-if="addpartner.company_email && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="company_address" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.company_address,'success':addpartner.company_address}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="addpartner.company_address" class="form-control" id="company_address" placeholder="Company Address*">
                                                                <label for="company_address" class="control-label">Company Address*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.company_address"></i>
                                                                <i class="fa fa-check" v-if="addpartner.company_address"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-12">
                                                    <ValidationProvider name="company_city" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.company_city,'success':addpartner.company_city}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="addpartner.company_city" class="form-control" id="company_city" placeholder="Company City*">
                                                                <label for="company_city" class="control-label">Company City*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.company_city"></i>
                                                                <i class="fa fa-check" v-if="addpartner.company_city"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <div class="form-group">
                                                        <span class="float-label">
                                                            <input type="text" v-model="addpartner.company_postcode" class="form-control" id="company_postcode" placeholder="Company Post code">
                                                            <label for="company_postcode" class="control-label">Company Post code</label>
                                                            <div class="line"></div>
                                                        </span>
                                                    </div>
                                                    <ValidationProvider name="company_phone" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.company_phone,'success':addpartner.company_phone}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="addpartner.company_phone" class="form-control" id="company_phone" placeholder="Company Phone*">
                                                                <label for="company_phone" class="control-label">Company Phone(Add country code)*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.company_phone"></i>
                                                                <i class="fa fa-check" v-if="addpartner.company_phone"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-top-line">
                                    <div class="header d-flex align-items-center justify-content-between">
                                        <h2>Payout Options</h2>
                                    </div>
                                    <div class="body">
                                        <div class="edit-form">
                                            <h6>Bank Accounts</h6><br/>
                                            <div class="row">
                                                <div class="col-sm-6 col-12">
                                                    <ValidationProvider name="account_name" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.account_name,'success':addpartner.account_name}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="addpartner.account_name" class="form-control" id="account_name" placeholder="Name on Account*">
                                                                <label for="account_name" class="control-label">Name on Account*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.account_name"></i>
                                                                <i class="fa fa-check" v-if="addpartner.account_name"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="bank_name" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.bank_name || errors[0],'success':addpartner.bank_name && !errors[0]}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="addpartner.bank_name" class="form-control" id="bank_name" placeholder="Bank Name*">
                                                                <label for="bank_name" class="control-label">Bank Name*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.bank_name || errors[0] "></i>
                                                                <i class="fa fa-check" v-if="addpartner.bank_name && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="branch_name" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.branch_name,'success':addpartner.branch_name}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="addpartner.branch_name" class="form-control" id="branch_name" placeholder="Branch Name*">
                                                                <label for="branch_name" class="control-label">Branch Name*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.branch_name"></i>
                                                                <i class="fa fa-check" v-if="addpartner.branch_name"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="account_number" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.account_number,'success':addpartner.account_number}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="addpartner.account_number" class="form-control" id="account_number" placeholder="Account Number*">
                                                                <label for="account_number" class="control-label">Account Number*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.account_number"></i>
                                                                <i class="fa fa-check" v-if="addpartner.account_number"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-12">
                                                    <ValidationProvider name="sort_code" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.sort_code,'success':addpartner.sort_code}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="addpartner.sort_code" class="form-control" id="sort_code" placeholder="Sort Code*">
                                                                <label for="sort_code" class="control-label">Sort Code*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.sort_code"></i>
                                                                <i class="fa fa-check" v-if="addpartner.sort_code"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="swift_code" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.swift_code,'success':addpartner.swift_code}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="addpartner.swift_code" class="form-control" id="swift_code" placeholder="Swift Code*">
                                                                <label for="swift_code" class="control-label">Swift Code*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.swift_code"></i>
                                                                <i class="fa fa-check" v-if="addpartner.swift_code"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="purpose" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.purpose,'success':addpartner.purpose}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="addpartner.purpose" class="form-control" id="purpose" placeholder="Purpose*">
                                                                <label for="purpose" class="control-label">Purpose*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.purpose"></i>
                                                                <i class="fa fa-check" v-if="addpartner.purpose"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edit-form">
                                            <h6>Mobile Money</h6><br/>
                                            <div class="row">
                                                <div class="col-sm-6 col-12">
                                                    <ValidationProvider name="subscriber_name" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.subscriber_name,'success':addpartner.subscriber_name}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="addpartner.subscriber_name" class="form-control" id="subscriber_name" placeholder="Subscriber Name*">
                                                                <label for="subscriber_name" class="control-label">Subscriber Name*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.subscriber_name"></i>
                                                                <i class="fa fa-check" v-if="addpartner.subscriber_name"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="bank_name" v-slot="{ errors}">
                                                        <div class="form-group form-select single-multiselect select-option-custom" :class="{'errors': errors[0],'success':addpartner.momonetwork && !errors[0]}">
                                                            <span class="float-label">
                                                            <label for="momonetwork">Mobile Network Name*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="addpartner.momonetwork"
                                                                    :show-labels="false" 
                                                                    :options="network_options">
                                                                </multiselect>
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="addpartner.momonetwork && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-12">
                                                    <ValidationProvider name="momonumber" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.momonumber,'success':addpartner.momonumber}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="addpartner.momonumber" class="form-control" id="momonumber" placeholder="Mobile Number*">
                                                                <label for="momonumber" class="control-label">Mobile Number(Add country code)*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.momonumber"></i>
                                                                <i class="fa fa-check" v-if="addpartner.momonumber"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="momopurpose" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !addpartner.momopurpose,'success':addpartner.momopurpose}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="addpartner.momopurpose" class="form-control" id="momopurpose" placeholder="Purpose*">
                                                                <label for="momopurpose" class="control-label">Purpose*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !addpartner.momopurpose"></i>
                                                                <i class="fa fa-check" v-if="addpartner.momopurpose"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-6 col-12">
                                                    <div class="form-group">
                                                        <span class="float-label">
                                                            <input type="number" v-model="addpartner.partner_fees" class="form-control" id="partner_fees" placeholder="Partner Fees">
                                                            <label for="partner_fees" class="control-label">Partner Fees</label>
                                                            <div class="line"></div>
                                                        </span>
                                                    </div>
                                                    <div class="form-group">
                                                        <span class="float-label">
                                                            <input type="text" v-model="addpartner.affiliate_code" class="form-control" id="affiliate_code" placeholder="Affiliate Code">
                                                            <label for="affiliate_code" class="control-label">Affiliate Code</label>
                                                            <div class="line"></div>
                                                        </span>
                                                    </div>
                                                    <div class="fancy-checkbox">
                                                        <label><input type="checkbox" v-model="addpartner.send_notification" id="send_notification" value="send_notification"><span>Send Email Notification to Customer</span></label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-12">
                                                    <ValidationProvider name="status" v-slot="{ errors}">
                                                        <div class="form-group form-select single-multiselect select-option-custom" :class="{'errors': errors[0],'success':addpartner.status && !errors[0]}">
                                                            <span class="float-label">
                                                            <label for="status">Status*</label>
                                                                <multiselect
                                                                    v-model="addpartner.status"
                                                                    :show-labels="false" 
                                                                    :options="status_options">
                                                                </multiselect>
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="addpartner.status && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="payment_form" v-slot="{ errors}">
                                                        <div class="form-group form-select single-multiselect select-option-custom" :class="{'errors': errors[0],'success':addpartner.payment_form && !errors[0]}">
                                                            <span class="float-label">
                                                            <label for="payment_form">Select Payment Form*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="addpartner.payment_form"
                                                                    :show-labels="false" 
                                                                    :options="payment_form_options">
                                                                </multiselect>
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="addpartner.payment_form && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <div class="form-group">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <label for="rcvcurrency" class="control-label">Receiving Currency:</label>
                                                            </div>
                                                            <div class="col-md-8">
                                                                <label class="fancy-radio"><input name="rcvcurrency" value="passport" type="radio"><span><i></i>Source Currency</span></label>
                                                                <label class="fancy-radio"><input name="rcvcurrency" value="drivinglcs" type="radio" checked><span><i></i>Destination Currency</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-md-row justify-content-center">
                                    <b-button variant="secondary btn-mw" size="lg" @click="cancel()">Cancel</b-button>
                                    <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Submit</b-button>
                                </div>
                            </form>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const { VueTelInput } = require('vue-tel-input')
export default {
    name: "AddPartnerComponent",
    components: {
        ValidationObserver,
        ValidationProvider,
        VueTelInput,
        Multiselect: () => import('vue-multiselect'),
        Datepicker: () => import('vuejs-datepicker'),
        BreadCrumb : () => import('@/components/BreadCrumb.vue')
    },
    data: () => ({
        addpartner: {
            country: { name: 'Ghana', flagClass: "flag-icon flag-icon-gh fi-xl fi-round",primaryNumberCode:'GH'},
            first_name:'',
            middle_name:'',
            last_name:'',
            contact_email:'',
            mobile_number:'',
            dob:'',
            address1:'',
            postcode:'',
            passwords: '',
            confirm_passwords:'',
            password_type: 'password',
            confirm_type: 'password',
            second_personal_detail:'',
            second_first_name:'',
            second_last_name:'',
            second_email:'',
            second_mobile:'',
            company_name:'',
            company_email:'',
            company_address:'',
            company_city:'',
            company_postcode:'',
            company_phone:'',
            account_name:'',
            bank_name:'',
            branch_name:'',
            account_number:'',
            sort_code:'',
            swift_code:'',
            purpose:'',
            subscriber_name:'',
            momonetwork:'',
            momonumber:'',
            momopurpose:'',
            partner_fees:'',
            affiliate_code:'',
            send_notification:'',
            status:'',
            payment_form:'',
            rcvcurrency:''
        },

        countrys_options: [
            { name: 'Ghana', flagClass:"flag-icon flag-icon-gh fi-xl fi-round",primaryNumberCode:'GH'},
            { name: 'Nigeria', flagClass: "flag-icon flag-icon-ng fi-xl fi-round",primaryNumberCode:'NG'},
            { name: 'South Africa' , flagClass: "flag-icon flag-icon-za fi-xl fi-round",primaryNumberCode:'ZA'},
            { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round",primaryNumberCode:'US'},
        ],
        countryBirth_options: [
            { id:1, name: 'Ghana', value: 'ghana'},
            { id:2, name: 'United Kingdom', value: 'uk'},
            { id:3, name: 'Nigeria', value: 'ng'},
            { id:4, name: 'South Africa', value: 'sa'},
            { id:5, name: 'United States', value: 'usa'}
        ],
        network_options: ['africell', 'airtel', 'AIRTEL', 'GoMoney', 'Mobicash', 'MOOV', 'MPESA', 'MTN', 'mtn', 'ORANGE', 'orange', 'Paga','telecome', 'TIGO', 'VODAFONE'],
        status_options: ['Active','Approve','Reject','Inactive'],
        payment_form_options: ['Event Form','Payment Form'],
        mobileInputError:'',
        primaryMobileNumber:{
            mode: "auto",
            defaultCountry:"GH",
            autoFormat:false,
            dropdownOptions:{
                showFlags: true,
                disabled:true,
                showDialCodeInSelection:true
            }, 
        },

        errorsDisplay:false,
        has_number:    false,
        has_lowercase: false,
        has_uppercase: false,
        has_special:   false,
    }),
    methods: {
        userSubmitData() {
            if(typeof(this.mobileInputError) == 'undefined'){
                this.mobileInputError = false
            }
            if(this.mobileInputError){
                console.log('submit');
            }
        },

        cancel(){
            this.$router.push({ path: '/customer/list' })
        },

        restrictSpecialCharacter(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        },

        setPrimaryNumberCountryCode(defaultCountry) {
            this.$refs.telInput.choose(defaultCountry.primaryNumberCode);
            // sessionStorage.setItem('countryCode',defaultCountry.primaryNumberCode);
        },
        
        mobileInputValidate(phoneObject){
            this.mobileInputError = phoneObject.valid
        },

        showPassword(field_name) {
            if(field_name == 'type_1'){
                this.addpartner.password_type = this.addpartner.password_type === 'password' ? 'text' : 'password';
            }else if(field_name == 'type_2'){
                this.addpartner.confirm_type = this.addpartner.confirm_type === 'password' ? 'text' : 'password';
            }
        },

        password_check: function () {
            this.has_number    = /\d/.test(this.addpartner.passwords)
            this.has_lowercase = /[a-z]/.test(this.addpartner.passwords)
            this.has_uppercase = /[A-Z]/.test(this.addpartner.passwords)
            this.has_special   = /[^A-Za-z0-9]/.test(this.addpartner.passwords)
            if(this.addpartner.passwords.length > 7 && this.has_number && this.has_lowercase &&  this.has_uppercase && this.has_special){
                return this.errorsDisplay = true
            }else{
                return this.errorsDisplay = false
            }
        },

        isCheckedInput (actionName) {
            this.countrys_options[actionName.id].isChecked = !this.countrys_options[actionName.id].isChecked
        },
    },computed: {
        isAllSelected: function() {
            return  (this.user.country.length === this.countrys_options.length)
        }
    }
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style>
.form-group.set-password .float-label>i.fa.fa-eye {
    right: 40px
}
.form-group.set-password .fa.fa-eye.grey {
    color: #777;
}
.form-group.set-password .fa.fa-eye.success {
    color: #22af46;
}
</style>