<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <!-- Summary -->
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card card-top-line">
                    <!-- Header -->
                    <div class="header d-flex justify-content-between align-items-center">
                        <h2>Beneficiary List</h2>
                    </div>
                    <!-- Members Listing-->
                    <div class="body pt-0">
                        <!-- Filters/Buttons -->
                        <div class="chart-top-action d-lg-flex justify-content-end py-3">
                            <!-- SearchBox Filters -->
                            <div class="mr-0 mr-md-3 action-top-search">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search Beneficiary" aria-label="Search" v-model="searchText" aria-describedby="search-transactions" v-on:keyup.enter="refreshBeneficiaryTable">
                                    <div class="input-group-append" @click="refreshBeneficiaryTable()">
                                        <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Toggle Filter Button -->
                            <div class="fliter-main mr-auto mt-3 mt-md-0">
                            </div>
                        </div>
                        <!-- All Members List -->
                        <b-table 
                            ref="beneficiaryTable"
                            responsive
                            table-class="mb-0 table-custom border"
                            head-variant="light"
                            :items="getBeneficiaryList" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(optrc)="data">
                                <i :class="data.value.class" style="font-size: 20px;"></i>
                            </template>
                            <template #cell(action)="row">
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item @click="showEdit(row)">Edit</b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 align-items-center justify-content-between table-pagination" :class="totalRows > 0?' d-flex':'d-none'">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" v-model="currentPageJump" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
import '@/plugins/sweetalert2'
import "@/plugins/vee-validate";
const LoaderComponent = require("@/components/admin/loader/LoaderComponent.vue").default;
export default {
    name:'BeneficiaryListComponent',
    components: {
        BreadCrumb : () => import('@/components/BreadCrumb.vue'),
        "main-loader": LoaderComponent,
        // Datepicker: () => import('vuejs-datepicker'),
        // 'transactionstoprow-component': () => import('@/components/core/TransactionsTopRow.vue')
    },
    methods: {        
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = this.currentPageJump = e.target.value
                } else {
                   this.currentPage = this.currentPageJump = paginationMenu;
                }
            } else {
                this.currentPage = this.currentPageJump = 1;
            }
        },

        showEdit(data){
            // console.log(data);
            sessionStorage.setItem('beneficiaryID',data.item.id);
            this.$router.push({ path: '/beneficiary/edit' })
        },

        refreshBeneficiaryTable() {
            this.$refs.beneficiaryTable.refresh();
        },

        async getBeneficiaryList(ctx) {
            try {
                this.$refs.loader.show();
                let page = "";
                let page_size = "";
                // let sortby = "";
                // let sort = false;
                if (ctx.currentPage != undefined) {
                    page = ctx.currentPage;
                } else {
                    page = 1;
                }
                if (ctx.perPage != undefined) {
                    page_size = ctx.perPage;
                } else {
                    page_size = 50;
                }
                // if (ctx.sortBy == "") {
                //     sortby = "createdAt";
                // } else {
                //     sortby = ctx.sortBy;
                // }
                // if (ctx.sortDesc == false) {
                //     sort = "DESC";
                // } else {
                //     sort = "ASC";
                // }

                this.currentPageJump = page;
                this.items = [];
                let paramData = {
                    // sorting: sort,
                    // sortby: sortby,
                    size: page_size,
                    page: page,
                    user_id:sessionStorage.getItem('customerID'),
                };

                if(this.searchText){
                    paramData.search_keyword = this.searchText
                }
                
                // console.log(paramData);
                const response = await this.$http.get(
                    this.$apiV2+"user/beneficiary-list/",
                    {
                        params: paramData,
                        headers: {
                            'Authorization': 'Basic YmRkMTc5NTY5MmQ1NTBiMWIwY2M0YmUzZmJkN2MyMTY6ZTczYTdhNDhjNDYzNDM5Nzg1MTUyNTI4MWE4NzdkMWY='
                        }
                    }
                );
                // console.log(response);
                // JSON responses are automatically parsed.
                if (response.data.code == "0085-000") {
                    response.data.data.map((item) => {
                        let customerLists = {
                            id:item.id,
                            // beneficiaryid:item.beneficiaryid,
                            name:item.firstname+' '+item.middlename+' '+ item.lastname,
                            mobile:item.mobile ? item.mobile : '-',
                            address: item.address ? item.address : '-',
                            city: item.city ? item.city : '-',
                            optrc: item.riskstatus==true ? { class: "icon-check text-success"}: { class: "icon-close text-danger"},
                            country:item.country ? item.country : '-',
                            action:''
                        };
                        this.items.push(customerLists);
                    });
                    this.totalRows = response.data.total_count; //query.total;
                    this.$refs.loader.hide();
                    return this.items;
                }
            } catch (error) {
                console.log(error);
                this.$refs.loader.hide();
            }
        },
    },data(){
        return{
            items:[
                // {
                //     name:'John Smith',
                //     mobile:'16102347489',
                //     address: 'Demo Test',
                //     city: 'Demo',
                //     optrc: 'Test',
                //     country:'Ghana',
                //     action:''
                // },
                // {
                //     name:'Charlotte Jake',
                //     mobile:'16102347557',
                //     address: 'Demo Test',
                //     city: 'Demo',
                //     optrc: 'Test',
                //     country:'Ghana',
                //     action:''
                // },
                // {
                //     name:'Grayson Shams',
                //     mobile:'16102347557',
                //     address: 'Demo Test',
                //     city: 'Demo',
                //     optrc: 'Test',
                //     country:'United Kingdom',
                //     action:''
                // },
                // {
                //     name:'Jacob Camly',
                //     mobile:'16102347557',
                //     address: 'Demo Test',
                //     city: 'Demo1',
                //     optrc: 'Test',
                //     country:'United Kingdom',
                //     action:''
                // },
                // {
                //     name:'Amelia Hank',
                //     mobile:'16102347557',
                //     address: 'Demo Test',
                //     city: 'Demo',
                //     optrc: 'Test',
                //     country:'United States',
                //     action:''
                // },
                // {
                //     name:'Michael Larry',
                //     mobile:'16102347557',
                //     address: 'Demo Test',
                //     city: 'Demo',
                //     optrc: 'Test',
                //     country:'United States',
                //     action:''
                // },
            ],
           
            fields: [
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'mobile', label: 'Mobile',sortable: true,thClass:'th_sortfix'},
                { key: 'address', label: 'Address',sortable: true,thClass:'th_sortfix'},
                { key: 'city', label: 'City',sortable: true,thClass:'th_sortfix'},
                { key: 'optrc', label: 'Opt for RC',sortable: true,thClass:'th_sortfix'},
                { key: 'country', label: 'Country',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'Action',thClass:'th_sortfix'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            totalRows: 1,
            currentPage: 1,
            currentPageJump: 1,
            perPage: 50,
            pageOptions: [50,100, 150, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',


            // isVisibleFilter: false,
            searchText: '',
        }
    },mounted() {
        // this.totalRows = this.items.length
    }

}
</script>