var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 
  'wrapper landing-page': _vm.landingClassListV2,
  'auth':_vm.authClassListV2,
  'wrapper landing-page standapp-main': _vm.standappClassListV2,
  'auth standapp-main':  _vm.authStandappClassListV2,
  'auth-wrapper landing-page auth':_vm.authClassList || _vm.authStandappClassList,
  'standapp':_vm.authStandappClassList},attrs:{"id":"wrapper"}},[_c(_vm.layout,{tag:"component"},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }