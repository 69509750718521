<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <ValidationObserver v-slot="{ passes }">
                        <div class="header">
                            <h2>User Management</h2>
                        </div>
                        <div class="body">
                            <form @submit.prevent="passes(basicSubmit)" id="basic-form">
                                <div class="row">
                                    <div class="col-lg-6 col-12">
                                        <ValidationProvider name="first_name" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !user.first_name,'success':user.first_name}">
                                                <span class="float-label">
                                                    <input type="text" v-model="user.first_name" class="form-control" id="first_name" placeholder="First Name*">
                                                    <label for="first_name" class="control-label">First Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !user.first_name"></i>
                                                    <i class="fa fa-check" v-if="user.first_name"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="last_name" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !user.last_name,'success':user.last_name}">
                                                <span class="float-label">
                                                    <input type="text" v-model="user.last_name" class="form-control" id="last_name" placeholder="Last Name*">
                                                    <label for="last_name" class="control-label">Last Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !user.last_name"></i>
                                                    <i class="fa fa-check" v-if="user.last_name"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="business_name" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !user.businessname,'success':user.businessname}">
                                                <span class="float-label">
                                                    <input type="text" v-model="user.businessname" class="form-control" id="business_name" placeholder="Business Name*">
                                                    <label for="business_name" class="control-label">Business Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !user.businessname"></i>
                                                    <i class="fa fa-check" v-if="user.businessname"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="contact_email" rules="required|email" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !user.contact_email || errors[0],'success':user.contact_email && !errors[0]}">
                                                <span class="float-label">
                                                    <input type="email" v-model="user.contact_email" class="form-control" name="email1" id="contact_email" placeholder="Contact Email Address*">
                                                    <label for="contact_email" class="control-label">Contact Email Address*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !user.contact_email || errors[0] "></i>
                                                    <i class="fa fa-check" v-if="user.contact_email && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="mobile_number" rules="required" v-slot="{ errors }">
                                            <div class="form-group" :class="{'errors': errors[0],'success':user.mobile_number && !errors[0]}">
                                                <span class="float-label">
                                                    <input type="text" class="form-control" id="mobile_number" placeholder="Contact Mobile Number" v-model="user.mobile_number">
                                                    <label for="mobile_number" class="control-label">Contact Mobile Number</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.mobile_number && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <ValidationProvider name="select_country" rules="required" v-slot="{ errors}">
                                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.country.length != 0  && !errors[0]}">
                                                <span class="float-label">
                                                <label for="Country">Select Country</label>
                                                    <multiselect 
                                                        placeholder="Select Country"
                                                        v-model="user.country" 
                                                        :show-labels="false" 
                                                        :options="countrys_options" 
                                                        :multiple="true"
                                                        :searchable="false" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        label="name" 
                                                        track-by="name"  
                                                        :limit="0" 
                                                        :limit-text="displayText">
                                                        <template slot="beforeList">
                                                            <li class="multiselect__element multiselect-all">
                                                                <input type="checkbox" @change="selectAllChanged"  :checked="isAllChecked"><span> All</span>
                                                            </li>
                                                        </template>
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.country.length != 0 && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="functions" rules="required" v-slot="{ errors }">
                                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.function_selected && !errors[0]}">
                                                <span class="float-label">
                                                <label for="functions">Select Function</label>
                                                    <multiselect
                                                        v-model="user.function_selected"
                                                        :multiple="true"
                                                        :searchable="false"
                                                        :options="function_options">
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.function_selected && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="roles" rules="required" v-slot="{ errors }">
                                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.roles_selected && !errors[0]}">
                                                <span class="float-label">
                                                    <label for="roles">Select Roles</label>
                                                    <multiselect
                                                        v-model="user.roles_selected"
                                                        :multiple="true"
                                                        :searchable="false"
                                                        :options="roles_options">
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.roles_selected && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="services" rules="required" v-slot="{ errors }">
                                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.services_selected && !errors[0]}">
                                                <span class="float-label">
                                                    <label for="Services">Select Services</label>
                                                    <multiselect
                                                        v-model="user.services_selected"
                                                        :multiple="true"
                                                        :searchable="false"
                                                        :options="services_options">
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.services_selected && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="access" rules="required" v-slot="{ errors }">
                                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.access_selected && !errors[0]}">
                                                <span class="float-label">
                                                    <label for="Access">Select Access Type</label>
                                                    <multiselect
                                                        v-model="user.access_selected"
                                                        :multiple="true"
                                                        :searchable="false"
                                                        :options="access_type_options">
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.access_selected && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </form>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Multiselect from 'vue-multiselect'
import BreadCrumb from '@/components/BreadCrumb'

export default {
    name: "AddComponent",
    components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        BreadCrumb
    },
    data: () => ({
        isAllChecked:false,
        function_options: ['Admin', 'Tech', 'HR', 'Risk', 'Compliance', 'Finance', 'Sales', 'Service', 'Teller','allowEmpty'],
        roles_options:['Owner', 'Admin', 'Manager', 'User', 'Teller'],
        services_options:['Dashboard', 'Transactions', 'User Management'],
        access_type_options:['Read', 'Write', 'Edit', 'Full'],
        countrys_options: [
            { name: 'India'},
            { name: 'United Kingdom' },
            { name: 'Greece' },
            { name: 'Finland'}
        ],

        user: {
            first_name:'',
            last_name:'',
            country: [],
            contact_email:'',
            mobile_number:'',
            businessname: '',
            function_selected: null,
            roles_selected: null,
            services_selected: null,
            access_selected: null,
        },
    }),
    methods: {
        basicSubmit() {
            console.log("Submitting to server!");
        },

        displayText(count){
            if (count === 0){
                return ''
            }else if (count === 1){
                return this.user.country[0].name
            }else if(count == this.countrys_options.length){
                this.isAllChecked = true;
                return count + ' Countrys Selected.'
            }else {
                this.isAllChecked = false;
                return  count + ' Countrys Selected.'
            }
        },

        selectAllChanged() {
            if (this.isAllSelected) {
               this.user.country = []
            }else{
               this.user.country = this.countrys_options.slice()
            }
        }
    },computed: {
        isAllSelected: function() {
            return  (this.user.country.length === this.countrys_options.length)
        }
    }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
.field {
    margin-bottom: 10px;
}

.form {
    margin-top: 20px;
}

span {
    display: block;
    margin-top: 3px;
   
}
</style>
