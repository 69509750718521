<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <!-- Summary -->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card top_report card-top-line">
                    <div class="row clearfix">
                        <div class="col-lg-3 col-md-6 col-sm-6" v-for="(toprow,index) in transactions_toprows" :key="index">
                            <transactionstoprow-component
                                :icon = "toprow.icon"
                                :text = "toprow.text"
                                :price = "toprow.price"
                            ></transactionstoprow-component>
                            <!-- 
                                :progress_class = "toprow.progress_class"
                                :progressbar_width = "toprow.progressbar_width"
                                :compared_text = "toprow.compared_text"
                             -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card card-top-line">
                    <!-- Header -->
                    <div class="header d-flex justify-content-between align-items-center">
                        <h2>Customers List</h2>
                    </div>
                    <!-- Members Listing-->
                    <div class="body pt-0">
                        <!-- Filters/Buttons -->
                        <div class="chart-top-action d-lg-flex justify-content-end py-3">
                            <!-- SearchBox Filters -->
                            <div class="mr-0 mr-lg-3 action-top-search">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Keywords" aria-label="Search" v-model="search.keywords" aria-describedby="search-transactions" v-on:keyup.enter="refreshCustomerTable">
                                    <div class="input-group-append" @click="refreshCustomerTable()">
                                        <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Toggle Filter Button -->
                            <div class="fliter-main mr-auto mt-3 mt-lg-0">
                                <b-button class="fliter-btn btn-lg btn-mw" :variant="isVisibleFilter ? 'primary' : 'outline-primary'" @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></b-button>
                            </div>
                            <!-- <div class="fliter-main mt-3 mt-lg-0">
                                <b-button class="fliter-btn btn-lg btn-mwy" :variant="'outline-primary'" @click="isVisibleFilter = false">Clear</b-button>
                            </div> -->
                            <!-- Toggle Filter Collapse -->
                            <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                                <div class="d-flex justify-content-between p-3">
                                    <div class="form-group datepicker">
                                        <b-input-group>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">From</span>
                                                <span class="input-group-text" @click="$refs.birthdate.showCalendar()"><i class="icon-calendar"></i></span>
                                                <datepicker class="form-control" ref="birthdate" v-model="search.from" :bootstrap-styling="false" placeholder=""  :typeable="true"></datepicker>
                                            </div>
                                        </b-input-group>    
                                    </div>
                                    <div class="form-group datepicker">
                                        <b-input-group>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">To</span>
                                                <span class="input-group-text" @click="$refs.birthdate1.showCalendar()"><i class="icon-calendar"></i></span>
                                                <datepicker class="form-control" ref="birthdate1" v-model="search.to" :bootstrap-styling="false" placeholder=""  :typeable="true"></datepicker>
                                            </div>
                                        </b-input-group>    
                                    </div>
                                    <div class="form-group">
                                        <input class="form-control" type="text" v-model="search.fname" placeholder="First Name"/>
                                        <div class="line"></div>
                                    </div>
                                    <div class="form-group">
                                        <input class="form-control" type="text" v-model="search.mname" placeholder="Middle Name"/>
                                        <div class="line"></div>
                                    </div>
                                    <div class="form-group">
                                        <input class="form-control" type="text" v-model="search.lname" placeholder="Last Name"/>
                                        <div class="line"></div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between p-3">
                                    <!-- <div class="form-group form-select single-multiselect">
                                        <span class="float-label">
                                            <multiselect
                                                placeholder="Select Remiter Group"
                                                v-model="search.rgroup"
                                                :show-labels="false" 
                                                :options="remiterOption" 
                                                label="name"
                                                :searchable="true">
                                            </multiselect> 
                                        </span>
                                        <div class="line"></div>
                                    </div> -->
                                    <div class="form-group form-select single-multiselect">
                                        <span class="float-label">
                                            <multiselect
                                                placeholder="Select Remiter Status"
                                                v-model="search.rstatus"
                                                :show-labels="false" 
                                                :options="remiterStatus" 
                                                label="name"
                                                :searchable="true">
                                            </multiselect> 
                                        </span>
                                        <div class="line"></div>
                                    </div>
                                    <div class="form-group form-select single-multiselect">
                                        <span class="float-label">
                                            <multiselect
                                                placeholder="Select Country"
                                                v-model="search.country"
                                                :show-labels="false" 
                                                :options="countryOption" 
                                                label="name"
                                                :searchable="true">
                                            </multiselect> 
                                        </span>
                                        <div class="line"></div>
                                    </div>
                                    <!-- <div class="form-group">
                                        <input class="form-control" type="text" v-model="search.keywords" placeholder="Enter Keywords"/>
                                        <div class="line"></div>
                                    </div> -->
                                    <div class="form-group">
                                        <button class="btn btn-primary h-100" @click="isVisibleFilter = true; refreshCustomerTable()">Apply</button>
                                    </div>
                                    <div class="form-group">
                                        <button class="btn btn-outline-danger h-100" @click="isVisibleFilter = false; resetFilterValue()">Cancel/Clear</button>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                        <!-- All Members List -->
                        <b-table 
                            responsive
                            ref="customerTable"
                            table-class="mb-0 table-custom border"
                            head-variant="light"
                            :items="getCustomerList" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(image)="data">
                                <div class="media-object flag-img mr-2">
                                    <img v-if="data.item.gender == 'Female'" :src="require(`@/assets/xs/female-placeholder.webp`)" alt="" width="50px" class="rounded-circle">
                                    <img v-else :src="require(`@/assets/xs/male-placeholder.webp`)" alt="" width="50px" class="rounded-circle">
                                    <!-- <span class="flag-icon flag-icon-gb fi-md fi-round"></span> -->
                                </div>
                            </template>
                            <!-- <template #cell(uuid)="data">
                                <b-button 
                                    id="button1" 
                                    variant="outline-primary" 
                                    size="sm" 
                                    v-b-tooltip.hover
                                    :data-original-title="data.value.id"
                                    :title="data.value.id" 
                                    @click.prevent="copyTooltip($event)">
                                    <i class="fa fa-2x fa-barcode"></i>
                                </b-button>
                            </template> -->
                            <template #cell(name)="data">
                                <span>{{data.value.name }}</span>
                            </template>
                            <template #cell(accType)="data">
                                <img v-if="data.item.accType == 'Basic'" width="20px" :src="require(`@/assets/account-type-svg/plan-basic.svg`)">
                                <img v-else-if="data.item.accType == 'GREY - Unvalidated Profiles'" width="20px" :src="require(`@/assets/account-type-svg/plan-grey.svg`)">
                                <img v-else-if="data.item.accType == 'Silver'" width="20px" :src="require(`@/assets/account-type-svg/plan-silver.svg`)">
                                <img v-else-if="data.item.accType == 'Silver+'" width="20px" :src="require(`@/assets/account-type-svg/plan-silver-plus.svg`)">
                                <img v-else-if="data.item.accType == 'Bronze'" width="20px" :src="require(`@/assets/account-type-svg/plan-bronze.svg`)">
                                <img v-else-if="data.item.accType == 'Red'" width="20px" :src="require(`@/assets/account-type-svg/plan-red.svg`)">
                                <span> {{ data.item.accType }}</span>
                            </template>
                            <template #cell(regdate)="data">
                                <span>{{data.value.date}}<br/><small class="ml-2">{{data.value.time}}</small></span>
                            </template> 
                            <template #cell(status)="data">
                                <p class="function-badge badge-group-table mb-0">
                                    <span class="badge ml-0" :class="data.value.class">{{ data.value.name }}</span>
                                </p>
                            </template>
                            <template #cell(action)='row'>
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item @click="showEdit(row)">Edit</b-dropdown-item>
                                    <b-dropdown-item @click="showBeneficiary(row)">View Beneficiary</b-dropdown-item>
                                    <!-- <b-dropdown-item href="/beneficiary/list" target="_blank">View Beneficiary</b-dropdown-item> -->
                                    <b-dropdown-item @click="showChange('Inactive')">Active </b-dropdown-item>
                                    <b-dropdown-item href="/customer/enable-fields">Enable Fields</b-dropdown-item>
                                    <b-dropdown-item @click="showAlert">Delete</b-dropdown-item>
                                    <b-dropdown-item>Member Login</b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 align-items-center justify-content-between table-pagination" :class="totalRows > 0?' d-flex':'d-none'">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" v-model="currentPageJump" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
// const { ValidationObserver,ValidationProvider} = require('vee-validate')
import '@/plugins/sweetalert2'
import "@/plugins/vee-validate";
import Multiselect from 'vue-multiselect';
const moment = require('moment')
const LoaderComponent = require("@/components/admin/loader/LoaderComponent.vue").default;
export default {
    name:'MemberListComponent',
    components: {
        BreadCrumb : () => import('@/components/BreadCrumb.vue'),
        Datepicker: () => import('vuejs-datepicker'),
        Multiselect,
        // ValidationObserver,
        // ValidationProvider,
        'transactionstoprow-component': () => import('@/components/core/TransactionsTopRow.vue'),
        "main-loader": LoaderComponent,
    },
    methods: {
        showAlert() {
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-secondary btn-lg'
                },
                buttonsStyling: false
            })
            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You want to delete this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    swalWithBootstrapButtons.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                    )
                }
            })
        },

        showChange(data){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-secondary btn-lg'
                },
                buttonsStyling: false
            })
            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You want to change this status "+data+" !",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    swalWithBootstrapButtons.fire(
                    'Success!',
                    'Your status has been changed successfully.',
                    'success'
                    )
                }
            })
        },
        
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = this.currentPageJump = e.target.value
                } else {
                   this.currentPage = this.currentPageJump = paginationMenu;
                }
            } else {
                this.currentPage = this.currentPageJump = 1;
            }
        },

        //Recent transactions table Details copy
        copyTooltip: function(e){
            var tooltipText = e.currentTarget.getAttribute('data-original-title');
            const inputCopyText = document.createElement('input')
            inputCopyText.value = tooltipText
            document.body.appendChild(inputCopyText)
            inputCopyText.select()
            document.execCommand('copy')
            document.body.removeChild(inputCopyText)
        },

        showEdit(data){
            // console.log(data.item.id);
            sessionStorage.setItem('editcustomerID',data.item.id); //'1e50f60c-6b2f-4bd2-94c2-39958a8b3799'
            this.$router.push({ path: '/customer/edit' })
        },

        showBeneficiary(data){
            // console.log(data);
            sessionStorage.setItem('customerID',data.item.id); //'748d4f72-daed-42a0-8187-919f93def7aa'
            let route = this.$router.resolve({ path: "/beneficiary/list" });
            window.open(route.href, '_blank');
        },

        async getCustomerList(ctx) {
            try {
                this.$refs.loader.show();
                let page = "";
                let page_size = "";
                let sortby = "";
                let sort = false;
                if (ctx.currentPage != undefined) {
                    page = ctx.currentPage;
                } else {
                    page = 1;
                }
                if (ctx.perPage != undefined) {
                    page_size = ctx.perPage;
                } else {
                    page_size = 50;
                }
                if (ctx.sortBy == "") {
                    sortby = "createdAt";
                } else {
                    sortby = ctx.sortBy;
                }
                if (ctx.sortDesc == false) {
                    sort = "DESC";
                } else {
                    sort = "ASC";
                }

                this.currentPageJump = page;
                this.items = [];
                // let paramData = {
                //     // sorting: sort,
                //     // sortby: sortby,
                //     size: page_size,
                //     page: page
                // };
                let pagesize = 'size='+page_size;
                let pagenumber = '&page='+page;
                let sorting = '&sort_order='+sort+'&sort_column='+sortby;

                let keywords = '';
                if(this.search.keywords){
                    // paramData.keywords = this.search.keywords
                    keywords = "&search_keyword="+this.search.keywords;
                }
                // console.log(paramData);

                let fname = '';
                let lname = '';
                let mname = '';
                let account_type = '';
                let status = '';
                let country = '';
                if(this.search.fname){
                    fname = '&filter_field=firstname&filter_value='+this.search.fname;
                }
                if(this.search.lname){
                    lname = '&filter_field=lastname&filter_value='+this.search.lname;
                }
                if(this.search.mname){
                    mname = '&filter_field=middlename&filter_value='+this.search.mname;
                }
                if(this.search.rgroup){
                    account_type = '&filter_field=account_type&filter_value='+this.search.rgroup.value
                }
                if(this.search.rstatus){
                    status = '&filter_field=status&filter_value='+ (this.search.rstatus.value == "N/A" ? '' : this.search.rstatus.value)
                }
                if(this.search.country){
                    country = '&filter_field=country&filter_value='+this.search.country.value
                }
                let fromdate = '';
                let todate = '';
                if(this.search.from){
                    fromdate = "&from_date="+moment(this.search.from).format('YYYY-MM-DD'); //2022-01-01
                }
                if(this.search.to){
                    todate = "&to_date="+moment(this.search.to).format('YYYY-MM-DD'); //2022-12-31
                }
                // console.log(fromdate);


                const response = await this.$http.get(
                    // this.$apiV2+"user/customer-list/",
                    this.$apiV2+"user/customer-list/?"+pagesize+sorting+pagenumber+keywords+fname+lname+mname+account_type+status+country+fromdate+todate,
                    {
                    // params: paramData,
                    headers: {
                        // Authorization: 'Basic YmRkMTc5NTY5MmQ1NTBiMWIwY2M0YmUzZmJkN2MyMTY6ZTczYTdhNDhjNDYzNDM5Nzg1MTUyNTI4MWE4NzdkMWY=',//"Bearer " + sessionStorage.getItem("jwtToken"),
                        Authorization : "Bearer " + sessionStorage.getItem("jwtToken"),
                    }
                    }
                );
                // console.log(response.data.results.code);
                // JSON responses are automatically parsed.
                if (response.data.results.code == "0131-000") {
                    response.data.results.data.map((item) => {
                        let statusobj = '';
                        if(item.status === 'Valid'){
                            statusobj = {name:'Valid',class:'badge-success'};
                        } else {
                            statusobj = {name:'N/A',class:'badge-grey'};
                        }

                        let customerLists = {
                            id:item.id,
                            uuid: {id:item.id},
                            name:{ name:item.firstname+' '+item.middlename+' '+ item.lastname },
                            city: item.city ? item.city : '-',
                            regdate:{ date: moment(item.createdat).format('DD-MM-YYYY'), time:moment(item.createdat).format('h:mm:ss') },
                            country:item.country ? item.country : '-',
                            mobileNumber:item.phone ? item.phone : '-',
                            email:item.email ? item.email : '-',
                            accType:item.account_type ? item.account_type : '-',
                            status:statusobj,
                            gender:item.gender,
                            action:''
                        };
                        this.items.push(customerLists);
                    });
                    this.totalRows = response.data.results.query.total;
                    this.$refs.loader.hide();
                    return this.items;
                }
            } catch (error) {
                console.log(error);
                this.$refs.loader.hide();
            }
        },

        refreshCustomerTable() {
            this.$refs.customerTable.refresh();
        },

        resetFilterValue(){
            this.search.fname = '';
            this.search.mname = '';
            this.search.lname = '';
            this.search.rgroup = null;
            this.search.rstatus = null;
            this.search.country = null;
            this.search.from = '';
            this.search.to = '';
            this.refreshCustomerTable();
        },
    },data(){
        return{
            items:[
                // {
                //     // details: {name:'John Smith',user_img: require(`@/assets/xs/avatar4.jpg`),flagClass: "flag-icon flag-icon-ca fi-md fi-round"},
                //     uuid: {id:'123'},
                //     name:{ name:'John Smith' },
                //     city: 'Test',
                //     regdate:{ date: '2022-11-16', time:'06:50:59' },
                //     country:'Ghana',
                //     mobileNumber:'16102347489',
                //     email:'john@gmail.com',
                //     accType:'Basic',
                //     status:{name:'Valid',class:'badge-success'},
                //     action:''
                // },
                // {
                //     // details: {name:'Charlotte Jake',user_img: require(`@/assets/xs/avatar1.jpg`),flagClass: "flag-icon flag-icon-gb fi-md fi-round"},
                //     uuid: {id:'456'},
                //     name:{ name:'Charlotte Jake' },
                //     city: 'Test1',
                //     regdate:{ date: '1985-11-18', time:'06:50:59' },
                //     country:'Ghana',
                //     mobileNumber:'16102347557',
                //     email:'charlotte@gmail.com',
                //     accType:'Grey',
                //     status:{name:'Blocked',class:'badge-danger'},
                //     action:''
                // },
                // {
                //     // details: {name:'Grayson Shams',user_img: require(`@/assets/xs/avatar3.jpg`),flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                //     uuid: {id:'789'},
                //     name:{ name:'Grayson Shams' },
                //     city: 'Demo',
                //     regdate:{ date: '1991-11-22', time:'06:50:59' },
                //     country:'United Kingdom',
                //     mobileNumber:'14844493850',
                //     email:'grayson@gmail.com',
                //     accType:'Basic',
                //     status:{name:'Expired',class:'badge-warning'},
                //     action:''
                // },
                // {
                //     // details: {name:'Jacob Camly',user_img: require(`@/assets/xs/avatar4.jpg`),flagClass: "flag-icon flag-icon-eu fi-md fi-round"},
                //     uuid: {id:'103'},
                //     name:{ name:'Jacob Camly' },
                //     city: 'Demo',
                //     regdate:{ date: '1993-11-22', time:'06:50:59' },
                //     country:'United Kingdom',
                //     mobileNumber:'15852135931',
                //     email:'jacob@gmail.com',
                //     accType:'Grey',
                //     status:{name:'Expired',class:'badge-warning'},
                //     action:''
                // },
                // {
                //     // details: {name:'Amelia Hank',user_img: require(`@/assets/xs/avatar2.jpg`),flagClass: "flag-icon flag-icon-fr fi-md fi-round"},
                //     uuid: {id:'120'},
                //     name:{ name:'Amelia Hank' },
                //     city: 'Demo',
                //     regdate:{ date: '1987-11-08', time:'06:50:59' },
                //     country:'Ghana',
                //     mobileNumber:'14844732134',
                //     email:'amelia@gmail.com',
                //     accType:'Basic',
                //     status:{name:'Valid',class:'badge-success'},
                //     action:''
                // },
                // {
                //     // details: {name:'Michael Larry',user_img: require(`@/assets/xs/avatar1.jpg`),flagClass: "flag-icon flag-icon-ru fi-md fi-round"},
                //     uuid: {id:'147'},
                //     name: { name:'Michael Larry' },
                //     city: 'Test',
                //     regdate:{ date: '1989-11-08', time:'06:50:59' },
                //     country:'United States',
                //     mobileNumber:'14842989674',
                //     email:'michael@gmail.com',
                //     accType:'Basic',
                //     status:{name:'Valid',class:'badge-success'},
                //     action:''
                // },
            ],
           
            fields: [
                { key: 'image', label: 'Profile image',sortable: true,thClass:'th_sortfix', thStyle:{width:'5%'},},
                // { key: 'journyid', label: 'Journy ID',sortable: true,thClass:'th_sortfix'},
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix', thStyle:{width:'10%'}, tdClass:'white-space-normal'},
                { key: 'email', label: 'Email',sortable: true,thClass:'th_sortfix', thStyle:{width:'10%'}, tdClass:'white-space-normal break-word-all'},
                { key: 'mobileNumber', label: 'Mobile Number',sortable: true,thClass:'th_sortfix'},
                { key: 'city', label: 'City',sortable: true,thClass:'th_sortfix'},
                { key: 'country', label: 'Country',sortable: true,thClass:'th_sortfix'},
                { key: 'regdate', label: 'Regn. Date',sortable: true,thClass:'th_sortfix'},
                { key: 'accType', label: 'Acc. Type',sortable: true,thClass:'th_sortfix', thStyle:{width:'10%'}, tdClass:'white-space-normal'},
                { key: 'status', label: 'Status',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'Action',thClass:'th_sortfix'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            totalRows: 1,
            currentPage: 1,
            currentPageJump: 1,
            perPage: 50,
            pageOptions: [50,100, 150, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            // newMemberUrl: window.location.origin+'/members-form/members-form.html',

            transactions_toprows:[
                {
                    icon:'fa fa-user-plus',
                    text:'New Customers(today)',
                    price:'220',
                    // progress_class:'bg-danger',
                    // progressbar_width:'87',
                    // compared_text:'1% compared to yesterday'
                },
                {
                    icon:'fa fa-calendar',
                    text:'New This Month',
                    price:'100',
                    // progress_class:'bg-success',
                    // progressbar_width:'28',
                    // compared_text:'19% compared to last month'
                },
                {
                    icon:'fa-users',
                    text:'Total Customers',
                    price:'3200',
                    // progress_class:'bg-info',
                    // progressbar_width:'41',
                    // compared_text:'19% compared to last year'
                },
                {
                    icon:'fa fa-users',
                    text:'Valid Customers',
                    price:'3000',
                    // progress_class:'bg-warning',
                    // progressbar_width:'75',
                    // compared_text:'19% compared to last year'
                }
            ],

            isVisibleFilter: false,

            search:{
                from:'',
                to:'',
                fname:'',
                mname:'',
                lname:'',
                rgroup:null,
                rstatus:null,
                country:null,
                keywords:''
            },

            remiterOption: [
                { value: 'Red', name:'Red'},
                { value: 'Grey', name:'Grey'},
                { value: 'Basic', name:'Basic'},
                { value: 'Bronze', name:'Bronze'},
                { value: 'Silver', name:'Silver'},
                { value: 'Silver Plus', name:'Silver Plus'},
            ],
            remiterStatus: [
                { value: 'N/A', name:'N/A'},
                { value: 'Valid', name:'Valid'},
                { value: 'Blocked', name:'Blocked'},
                { value: 'Expired', name:'Expired'},
            ],
            countryOption: [
                { value: 'Australia', name:'Australia'},
                { value: 'Canada', name:'Canada'},
                { value: 'France', name:'France'},
                { value: 'Germany', name:'Germany'},
                { value: 'Ghana', name:'Ghana'},
                { value: 'India', name:'India'},
                { value: 'Italy', name:'Italy'},
                { value: 'Netherlands', name:'Netherlands'},
                { value: 'Spain', name:'Spain'},
                { value: 'Switzerland', name:'Switzerland'},
                { value: 'United Kingdom', name:'United Kingdom'},
                { value: 'United States', name:'United States'},
            ]
        }
    },mounted() {
        this.totalRows = this.items.length
    }

}
</script>