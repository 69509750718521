<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <!-- Recent transactions -->
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card card-top-line">
                    <div class="header d-flex align-items-center justify-content-between">
                        <h2>Country List</h2>
                    </div>
                    <div class="body pt-0">
                        <div class="chart-top-action d-md-flex justify-content-end py-3">
                            <!-- SearchBox Filters -->
                            <div class="mr-0 mr-md-3 action-top-search">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search country" aria-label="Search country" aria-describedby="search-country" v-model="searchText" v-on:keyup.enter="refreshCountryTable">
                                    <div class="input-group-append" @click="refreshCountryTable()">
                                        <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Toggle Filter Button -->
                            <div class="fliter-main mr-auto mt-3 mt-md-0">
                            </div>

                            <!-- <div class="payment-dropdown d-flex flex-lg-row-reverse flex-column ml-lg-3 ml-0 mt-3 mt-lg-0">
                                <b-button size="lg" variant="primary btn-mw">Add New <i class="fa fa-plus-square"></i></b-button>
                                <button class="btn btn-outline-primary btn-lg mr-3">Download CSV <i class="fa fa-download ml-1"></i></button>
                                <button class="btn btn-outline-primary btn-lg mr-3" v-b-modal.upload_member>Upload <i class="fa fa-upload ml-1"></i></button>

                                <b-modal id="upload_member" title="Upload Member" ok-title="Upload" hide-footer>
                                    <div class="modal-area">
                                        <ValidationObserver v-slot="{ passes }">
                                            <form @submit.prevent="passes(uploadMemberDataSubmit)" id="uploadMember-form" ref="form">
                                                <div class="d-flex flex-column flex-md-row justify-content-start align-items-center">
                                                    <ValidationProvider name="csv_excel_file" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !uploadMember.csvExcelFile,'success':uploadMember.csvExcelFile}">
                                                            <div class="custom-file">
                                                                <b-form-file
                                                                    v-model="uploadMember.csvExcelFile"
                                                                    placeholder="Select Excel/CSV*"
                                                                    drop-placeholder="Drop file here..."
                                                                    accept=".xls,.csv"
                                                                ></b-form-file>
                                                                <i class="fa fa-times" v-if="errors.length && !uploadMember.csvExcelFile"></i>
                                                                <i class="fa fa-check" v-if="uploadMember.csvExcelFile"></i>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                    <div class="form-group">
                                                        <b-button variant="outline-primary ml-md-3 ml-0 btn-mw" size="lg">Download Template</b-button>
                                                    </div>
                                                </div>
                                                <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                    <b-button type="submit" variant="primary btn-mw" size="lg">Upload</b-button>
                                                    <b-button @click="$bvModal.hide('upload_member')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                                </div>
                                            </form>
                                        </ValidationObserver>
                                    </div>
                                </b-modal>
                            </div> -->
                        </div>
                        <b-table 
                            ref="countryTable"
                            responsive
                            hover 
                            outlined
                            table-class="js-basic-example table-custom mb-0"
                            head-variant="light"
                            :items="getCountryData" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection" :emptyText="'No data found.'" show-empty>
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(uuid)="data">
                                <b-button 
                                    id="button1" 
                                    variant="outline-primary" 
                                    size="sm" 
                                    v-b-tooltip.hover
                                    :data-original-title="data.value.countryid"
                                    :title="data.value.countryid"
                                    @click.prevent="copyTooltip($event)">
                                    <i class="fa fa-2x fa-barcode"></i>
                                </b-button>
                            </template>
                            <template #cell(details)="data">
                                <a class="cursor-pointer font-color font-weight-bold" @click="setCountry(data)">
                                <!-- <router-link class="font-color font-weight-bold" :to="'/country/details/' + data.value.id"> -->
                                    <div class="align-items-center d-flex">
                                        <span :class="data.value.flagClass" class="mr-2"></span>
                                        <span>{{ data.value.name }}</span>
                                    </div>
                                <!-- </router-link> -->
                                </a>
                            </template>
                            <template #cell(domestic)="data">
                                <div class="custom-switch success-bg">
                                    <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked" @click="updateStatus($event, 'domestic', data)">
                                    <!-- @click="updateStatus($event, 'domestic', data)" -->
                                    <label class="custom-switch-label" :for="data.value.id">
                                        <span class="custom-switch-text1">Yes</span>
                                        <span class="custom-switch-text2">No</span>
                                    </label>
                                </div>
                            </template>
                            <template #cell(destination)="data">
                                <div class="custom-switch success-bg">
                                    <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked" @click="updateStatus($event, 'destination', data)">
                                    <!-- @click="updateStatus($event, 'destination', data)" -->
                                    <label class="custom-switch-label" :for="data.value.id">
                                        <span class="custom-switch-text1">Yes</span>
                                        <span class="custom-switch-text2">No</span>
                                    </label>
                                </div>
                            </template>
                            <template #cell(crossborder)="data">
                                <div class="custom-switch success-bg">
                                    <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked" @click="updateStatus($event, 'crossborder', data)">
                                    <!-- @click="updateStatus($event, 'crossborder', data)" -->
                                    <label class="custom-switch-label" :for="data.value.id">
                                        <span class="custom-switch-text1">Yes</span>
                                        <span class="custom-switch-text2">No</span>
                                    </label>
                                </div>
                            </template> 
                            <template #cell(isblacklisted)="data">
                                <div class="custom-switch success-bg">
                                    <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked" @click="updateStatus($event, 'isblacklisted', data)"> 
                                    <!-- @click="updateStatus($event, 'isblacklisted', data)" -->
                                    <label class="custom-switch-label" :for="data.value.id">
                                        <span class="custom-switch-text1">Yes</span>
                                        <span class="custom-switch-text2">No</span>
                                    </label>
                                </div>
                            </template> 
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 align-items-center justify-content-between table-pagination" :class="totalRows > 0?' d-flex':'d-none'">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" v-model="currentPageJump" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </div>
</template>

<script>
  import BreadCrumb from '@/components/BreadCrumb'
  const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
  import shared from "@/shared.js";
//   import { ValidationObserver, ValidationProvider } from "vee-validate";
  export default  {
    name: 'country-component',
    components: {
        BreadCrumb,
        // ValidationObserver,
        // ValidationProvider,
        'main-loader':LoaderComponent
    },data (){
        return{
            isVisibleFilter: false,

            //Recent transactions table data
            items:[],
           
            //Recent transactions table header
            fields: [
                { key: 'uuid', label: 'UUID',sortable: true,thClass:'th_sortfix'},
                { key: 'details', label: 'Name',sortable: true,thClass:'th_sortfix'},
                // { key: 'isocode', label: 'Alpha-2'},
                // { key: 'isocode3', label: 'Alpha-3'},
                // { key: 'numeric', label: 'Numeric' },
                { key: 'dialcode', label: 'Dialling Code' },
                { key: 'currency', label: 'Currency' },
                { key: 'domestic', label: 'Domestic'},
                { key: 'crossborder', label: 'Cross Border'},
                { key: 'destination', label: 'Destination'},
                { key: 'isblacklisted', label: 'Blacklist'},
                // { key: 'action', label: 'Status'},
                { key: 'show_details', label: '', thStyle:'width:40px'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //Recent transactions table pagination
            totalRows: 0,
            currentPage: 1,
            currentPageJump: 1,
            perPage: 50,
            pageOptions: [50 , 100, 150, { value: 100, text: 100 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            searchText: '',
            businessId:'',
            countryitems:[]
        }
    },methods: {
        //Recent transactions table go to pagination
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = this.currentPageJump = e.target.value
                } else {
                   this.currentPage = this.currentPageJump = paginationMenu;
                }
            } else {
                this.currentPage = this.currentPageJump = 1;
            }
        },

        //Recent transactions table Details copy
        copyTooltip: function(e){
            var tooltipText = e.currentTarget.getAttribute('data-original-title');
            const inputCopyText = document.createElement('input')
            inputCopyText.value = tooltipText
            document.body.appendChild(inputCopyText)
            inputCopyText.select()
            document.execCommand('copy')
            document.body.removeChild(inputCopyText)
        },

        updateStatus(event, key, row){
            this.$refs.loader.show();
            // console.log(event.target.checked, key, row);
            let dataJson = {
                'domestic' : key==='domestic'? event.target.checked : row.item.domestic.isChecked,
                'crossborder' : key==='crossborder'? event.target.checked: row.item.crossborder.isChecked,
                'destination' : key==='destination'? event.target.checked: row.item.destination.isChecked,
                'blacklisted' : key==='isblacklisted'? event.target.checked: row.item.isblacklisted.isChecked, 
            }
            // console.log(dataJson);
            this.$http.post(this.$baseurl+"countries/fintech/"+this.businessId+'/'+row.item.countryid, dataJson,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                },
            }).then((res) => {
                // console.log(res);
                if(res.data.code == '0003-000') {
                    this.refreshCountryTable();
                    shared.toastrSuccess(res.data.message);
                    this.$refs.loader.hide();
                } else {
                    this.refreshCountryTable();
                    this.$refs.loader.hide();
                }
            }).catch((error) => {
                // console.log(error.response);
                this.refreshCountryTable();
                this.$refs.loader.hide();
                shared.toastrError(error.response.data.message);
            });
        },

        async getCountryData(ctx) {
            try {
                this.$refs.loader.show();
                let page  = '';
                let size = '';
                let sortby = '';
                let sort = false;
                if(ctx.currentPage != undefined) {
                    page =  ctx.currentPage;
                } else {
                    page = 1;
                }
                if(ctx.perPage != undefined) {
                    size = ctx.perPage;
                } else {
                    size = 50;
                }
                if(ctx.sortBy == '') {
                    sortby = 'createdat'
                } else {
                    sortby = ctx.sortBy;
                }
                if(ctx.sortDesc == false) {
                    sort = 'asc'
                } else {
                    sort = 'desc';
                }

                
                this.currentPageJump = page;
                this.items = [];
                let paramData = {
                    sort : sort,
                    sortby : sortby,
                    size : size,
                    page : page,
                    search : this.searchText
                }
                const response = await this.$http.get(this.$baseurl+"countries/available", { 
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log('test',this.countryitems);
                response.data.details.map((item, index) => {

                    let domesticStatus = '';
                    let crossborder = '';
                    let destination = '';
                    let blacklist = '';

                    if(this.countryitems.some(el => el.countryID === item.countryid)){
                        // console.log(item.countryname);
                        this.countryitems.some((el) => {
                            // console.log(el);
                            if(el.countryID === item.countryid){
                                //code here 
                                domesticStatus = el.domestic;
                                crossborder = el.crossborder;
                                destination = el.destination;
                                blacklist = el.isblacklisted;
                            }
                        })
                    } else {
                        domesticStatus = item.domestic;
                        crossborder = item.crossborder;
                        destination = item.destination;
                        blacklist = item.isblacklisted;
                    }
                    
                    let lists = {
                        uuid:{countryid:item.countryid},
                        countryid:item.countryid,
                        details: {id:item.countryid,name:item.countryname,flagClass: "flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-md fi-round"},
                        countryIsoCode:item.isocode,
                        isocode:item.alpha2,
                        isocode3:item.alpha3,
                        currency:item.currencycode,
                        numeric:item.isonumeric,
                        dialcode:item.mobilecountrycode,
                        domestic:{id:'domestic_'+index,isChecked:domesticStatus,countryId:item.countryid},
                        destination:{id:'destination_'+index,isChecked:destination,countryId:item.countryid},
                        crossborder:{id:'crossborder_'+index,isChecked:crossborder,countryId:item.countryid},
                        isblacklisted:{id:'isblacklisted_'+index,isChecked:blacklist,countryId:item.countryid},
                        show_details:''
                    };
                    this.items.push(lists);
                }); 
                this.totalRows = response.data.query.total;
                this.$refs.loader.hide();
                return this.items;               
            } catch (error) {
                console.log(error);
                this.$refs.loader.hide();
            }
        },

        refreshCountryTable() {
            // console.log('refresh');
            // this.fintechbusiness(this.businessId);
            this.$refs.countryTable.refresh();
        },

        setCountry(data){
            // console.log('Set',data);
            sessionStorage.setItem('countryId', data.item.countryid);
            sessionStorage.setItem('countryDomestic', data.item.domestic.isChecked);
            sessionStorage.setItem('countryDestination', data.item.destination.isChecked);
            sessionStorage.setItem('countryCrossborder', data.item.crossborder.isChecked);
            this.$router.push({path:'/country/details'});
        },

        async fintechbusiness(businessid){
            try {
                this.countryitems = [];
                const response = await this.$http.get(this.$baseurl+"countries/fintech/"+businessid, { 
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                response.data.details.map((item) => {
                    let countryLists = {
                        countryID:item.countryid,
                        businessID:item.businessid,
                        domestic:item.domestic,
                        destination:item.destination,
                        crossborder:item.crossborder,
                        isblacklisted:item.isblacklisted
                    };
                    this.countryitems.push(countryLists);
                }); 
                return this.countryitems;               
            } catch (error) {
                console.log(error);
            }
        }
    },mounted(){
        if(sessionStorage.getItem('businessID')){
            this.businessId = sessionStorage.getItem('businessID');
            this.fintechbusiness(this.businessId);
        }
        
    }
}
</script>

<style>
/* .flag-align{
    bottom: -4px;
} */
</style>
