<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <ValidationObserver v-slot="{ passes }">
                        <div class="header">
                            <h2>Enable Edit Profile</h2>
                        </div>
                        <div class="body">
                            <form @submit.prevent="passes(userSubmitData)" id="basic-form">
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <div class="d-flex align-items-center mb-3">
                                            <p class="mb-0 mr-3 custom-switch-text">Enable First Name:</p>
                                            <div class="custom-switch success-handle">
                                                <input type="checkbox" class="custom-switch-input" id="firstname" v-model="user.first_name">
                                                <label class="custom-switch-label" for="firstname">
                                                    <span class="custom-switch-text1">Yes</span>
                                                    <span class="custom-switch-text2">No</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3">
                                            <p class="mb-0 mr-3 custom-switch-text">Enable Middle Name:</p>
                                            <div class="custom-switch success-handle">
                                                <input type="checkbox" class="custom-switch-input" id="middlename" v-model="user.middle_name">
                                                <label class="custom-switch-label" for="middlename">
                                                    <span class="custom-switch-text1">Yes</span>
                                                    <span class="custom-switch-text2">No</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3">
                                            <p class="mb-0 mr-3 custom-switch-text">Enable Last Name:</p>
                                            <div class="custom-switch success-handle">
                                                <input type="checkbox" class="custom-switch-input" id="lastname" v-model="user.last_name">
                                                <label class="custom-switch-label" for="lastname">
                                                    <span class="custom-switch-text1">Yes</span>
                                                    <span class="custom-switch-text2">No</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3">
                                            <!-- justify-content-between -->
                                            <p class="mb-0 mr-3 custom-switch-text">Enable Gender:</p>
                                            <div class="custom-switch success-handle">
                                                <input type="checkbox" class="custom-switch-input" id="gender" v-model="user.gender">
                                                <label class="custom-switch-label" for="gender">
                                                    <span class="custom-switch-text1">Yes</span>
                                                    <span class="custom-switch-text2">No</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="d-flex align-items-center mb-3">
                                            <p class="mb-0 mr-3 custom-switch-text">Enable Mobile:</p>
                                            <div class="custom-switch success-handle">
                                                <input type="checkbox" class="custom-switch-input" id="mobile" v-model="user.mobile">
                                                <label class="custom-switch-label" for="mobile">
                                                    <span class="custom-switch-text1">Yes</span>
                                                    <span class="custom-switch-text2">No</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3">
                                            <p class="mb-0 mr-3 custom-switch-text">Enable DOB:</p>
                                            <div class="custom-switch success-handle">
                                                <input type="checkbox" class="custom-switch-input" id="dob" v-model="user.dob">
                                                <label class="custom-switch-label" for="dob">
                                                    <span class="custom-switch-text1">Yes</span>
                                                    <span class="custom-switch-text2">No</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3">
                                            <p class="mb-0 mr-3 custom-switch-text">Enable Email:</p>
                                            <div class="custom-switch success-handle">
                                                <input type="checkbox" class="custom-switch-input" id="email" v-model="user.email">
                                                <label class="custom-switch-label" for="email">
                                                    <span class="custom-switch-text1">Yes</span>
                                                    <span class="custom-switch-text2">No</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3">
                                            <p class="mb-0 mr-3 custom-switch-text">Enable Nationality:</p>
                                            <div class="custom-switch success-handle">
                                                <input type="checkbox" class="custom-switch-input" id="nationality" v-model="user.nationality">
                                                <label class="custom-switch-label" for="nationality">
                                                    <span class="custom-switch-text1">Yes</span>
                                                    <span class="custom-switch-text2">No</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-md-row justify-content-center">
                                    <b-button variant="secondary btn-mw" size="lg" @click="cancel()">Cancel</b-button>
                                    <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Submit</b-button>
                                </div>
                            </form>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const { ValidationObserver} = require('vee-validate')
import "@/plugins/vee-validate";
export default {
    name: "EnableFieldsComponent",
    components: {
        ValidationObserver,
        BreadCrumb : () => import('@/components/BreadCrumb.vue')
    },
    data: () => ({
        user: {
            first_name:'',
            middle_name:'',
            last_name:'',
            gender:'',
            mobile:'',
            dob:'',
            email:'',
            nationality:''
        },
    }),
    methods: {
        userSubmitData() {
            console.log('submit');
        },

        cancel(){
            this.$router.push({ path: '/customer/list' })
        },
    }
};
</script>