import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

//layouts file
Vue.component("auth-layout", () => import("@/components/admin/layouts/main_layout/AuthLayout.vue"));
Vue.component("default-layout", () => import("@/components/admin/layouts/main_layout/DefaultLayout.vue"));
Vue.component("standapp-layout", () => import("@/components/admin/layouts/main_layout/Standapp.vue"));
Vue.component("auth-v2-layout", () => import("@/components/admin/layouts/main_layout/AuthV2Layout.vue"));
Vue.component("standapp-v2-layout", () => import("@/components/admin/layouts/main_layout/StandappV2.vue"));
Vue.component("customer-layout", () => import("@/components/admin/layouts/main_layout/CustomerHomeLayout.vue"));

//BootstrapVue
import { BootstrapVue} from 'bootstrap-vue'
Vue.use(BootstrapVue)

//Import vue-google-map-plugin
import * as VueGoogleMaps from "vue2-google-maps"
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAB1e2r8zgZIEb6c5U-d-Kikss-iMMiuRM",
        libraries: "places",
    },
});

//axios
import axios from 'axios';
Vue.prototype.$http = axios;
import store from './store';


// Declare base url
Vue.prototype.$baseurl  = process.env.VUE_APP_NODE_URL;
Vue.prototype.$apiV2 = process.env.VUE_APP_PYTHON_URL;
Vue.prototype.$standapp = process.env.VUE_APP_STANDAPP_URL;
Vue.prototype.$payangel = process.env.VUE_APP_PAYANGEL_URL;
Vue.prototype.$apiV2transaction = process.env.VUE_APP_PYTHON_TRANSACTION_URL;


const routes= [
    //front routes
    {
        path: '',
        redirect: '/page-login',
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/components/admin/home/HomeComponent.vue'),
        meta: {title: "Home Page", layout:'auth-v2'},
    },
    {
        path: '/page-login',
        name: 'login',
        component: require('@/components/admin/authentication/LoginComponent.vue').default,
        meta: {title: "Login", layout:'auth'},
    },
    { 
        path: '/set-password',
        name: 'ssetpassword', 
        component: require('@/components/admin/authentication/StandappSetPasswordComponent.vue').default, 
        meta: {title: "Set Password", layout:'auth'}
    },
    {
        path: '/forgot-password', 
        name: 'sforgotpassword', 
        component: require('@/components/admin/authentication/ForgotPasswordComponent.vue').default, 
        meta: {title: "Forgot Password", layout:'auth'}
    },
    { 
        path: '/reset-password', 
        name: 'sresetpassword', 
        component: require('@/components/admin/authentication/ResetPasswordComponent.vue').default, 
        meta: {title: "Reset Password", layout:'auth'}
    },
    { 
        path: '/recover-password', 
        name: 'srecoverpassword', 
        component: require('@/components/admin/authentication/RecoverPasswordComponent.vue').default, 
        meta: {title: "Recover Password", layout:'auth'}
    },


    // {
    //     path: '/authentication',
    //     redirect: '/page-*',
    //     component: () => import('@/components/admin/uikit/RouterView.vue'),
    //     children: [
    //         { path: 'page-login', component: () => import('@/components/admin/authentication/LoginComponent.vue'),name: 'login', meta: { title: "Login", layout:'auth' }},
    //     ]
    // },

    //backend routes
    {
        path: '/dashboard-v1',
        component: () => import('@/components/admin/dashboard/DashboardV1Component.vue'),
        meta: {breadCrumb: "Dashboard", title: "Dashboard"},
    },
    // {
    //     path: '/profile',
    //     component: () => import('@/components/admin/uikit/RouterView.vue'),
    //     meta: {breadCrumb: "Edit Profile", title: "EditProfile"},
    //     children: [
    //         { path: 'edit-profile',component: () => import('@/components/admin/dashboard/dashboard_v2_widgets/profile/EditProfile.vue'), meta: {breadCrumb: "Dashboard V2", title: "Edit Profile"}},
    //     ]
    // },

    {
        path: '/customer',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        meta: {breadCrumb: "Customer", title: "Customer"},
        children: [
            { path: 'list',component: require('@/components/admin/customer/CustomerListComponent.vue').default, meta: { breadCrumb: "Customer List", title: "Customer List"}},
            { path: 'edit', component: require('@/components/admin/customer/EditCustomerComponent.vue').default, meta: { breadCrumb: "Edit Customer", title: "Edit Customer" } },
            { path: 'enable-fields',component: require('@/components/admin/customer/EnableFieldsComponent.vue').default, meta: { breadCrumb: "Enable Edit Profile", title: "Enable Edit Profile"}},
        ]
    },
    {
        path: '/beneficiary',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        meta: {breadCrumb: "Beneficiary", title: "Beneficiary"},
        children: [
            { path: 'list', component: require('@/components/admin/customer/BeneficiaryListComponent.vue').default, meta: { breadCrumb: "Beneficiary List", title: "Beneficiary List" } },
            { path: 'all',component: require('@/components/admin/customer/AllBeneficiaryComponent.vue').default, meta: { breadCrumb: "Beneficiary List", title: "Beneficiary List"}},
            { path: 'edit',component: require('@/components/admin/customer/EditBeneficiaryComponent.vue').default, meta: { breadCrumb: "Edit Beneficiary", title: "Edit Beneficiary"}},
        ]
    },
    {
        path: '/transaction',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        meta: {breadCrumb: "Transaction", title: "Transaction"},
        children: [
            { path: 'arch',component: require('@/components/admin/transaction/TransactionListComponent.vue').default, meta: { breadCrumb: "Transaction List", title: "Transaction List"}},
            { path: 'details', component: require('@/components/admin/transaction/TransactionDetailComponent.vue').default, meta: { breadCrumb: "Transaction Details", title: "Transaction Details" } },
            { path: 'edit', component: require('@/components/admin/transaction/TransactionEditComponent.vue').default, meta: { breadCrumb: "Transaction Edit", title: "Transaction Edit" } },
            { path: 'middleware', component: require('@/components/admin/transaction/MiddlewareTransactionListComponent.vue').default, meta: { breadCrumb: "Middleware Transaction List", title: "Transaction List" } },
        ]
    },

    {
        path: '/partner',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        meta: {breadCrumb: "Partner", title: "Partner"},
        children: [
            { path: 'list',component: require('@/components/admin/partner/PartnerListComponent.vue').default, meta: { breadCrumb: "Partner List", title: "Partner List"}},
            { path: 'add', component: require('@/components/admin/partner/AddPartnerComponent.vue').default, meta: { breadCrumb: "Add Partner", title: "Add Partner" } },
            { path: 'edit',component: require('@/components/admin/partner/EditPartnerComponent.vue').default, meta: { breadCrumb: "Edit Partner", title: "Edit Partner"}},
        ]
    },
    {
        path: '/validate-bank-account',
        component: () => import('@/components/admin/bank/ValidateBankComponent.vue'),
        meta: {breadCrumb: "Validate Bank Account", title: "Validate Bank Account"},
    },
    {
        path: '/user',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        meta: {breadCrumb: "User", title: "User"},
        children: [
            { path: 'list',component: require('@/components/admin/user/ListComponent.vue').default, meta: { breadCrumb: "User List", title: "User List"}},
            { path: 'add', component: require('@/components/admin/user/AddComponent.vue').default, meta: { breadCrumb: "Add User", title: "Add User" } },
        ]
    },
    // {
    //     path: '/cms',
    //     component: require('@/components/admin/uikit/RouterView.vue').default,
    //     meta: {breadCrumb: "CMS", title: "CMS"},
    //     children: [
    //         { path: 'list',component: require('@/components/admin/cms/ListComponent.vue').default, meta: { breadCrumb: "Cms List", title: "Cms List"}},
    //         // { path: 'add', component: require('@/components/admin/user/AddComponent.vue').default, meta: { breadCrumb: "Add User", title: "Add User" } },
    //     ]
    // },
    {
        path: '/country',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        meta: {breadCrumb: "Country", title: "Country"},
        children: [
            { path: 'list', component: require('@/components/admin/country/ListComponent.vue').default, meta: { breadCrumb: "List", title: "Country List" } },
            { path: 'details',component: require('@/components/admin/country/CountryDetailComponent.vue').default, meta: { breadCrumb: "Details", title: "Country Details"}},
        ]
    },

    {
        path: '/list',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        meta: {breadCrumb: "List", title: "List"},
        children: [
            { path: 'payout-option', component: () => import('@/components/admin/list/PayoutComponent.vue'), meta: { breadCrumb: "Payout Option", title: "Payout Option" } },
            { path: 'payment-option', component: () => import('@/components/admin/list/PaymentComponent.vue'), meta: { breadCrumb: "Payment Option", title: "Payment Option" } },
            { path: 'service-type', component: () => import('@/components/admin/list/ServiceTypeComponent.vue'), meta: { breadCrumb: "Service Type", title: "Service Type" } },
            { path: 'bank', component: () => import('@/components/admin/list/BankComponent.vue'), meta: { breadCrumb: "Bank List", title: "Bank List" } },
            { path: 'provider', component: () => import('@/components/admin/list/BankWiseProviderList.vue'), meta: { breadCrumb: "Provider List", title: "Provider List" } },
            { path: 'benificiary-relationship', component: () => import('@/components/admin/list/BenificiaryRelationComponent.vue'), meta: { breadCrumb: "Benificiary Relationship", title: "Benificiary Relationship" } },
            { path: 'business-relationship', component: () => import('@/components/admin/list/BusinessRelationshipComponent.vue'), meta: { breadCrumb: "Business Relationship", title: "Business Relationship"}},
        ]
    },

    // {
    //     path: '/partner',
    //     component: require('@/components/admin/uikit/RouterView.vue').default,
    //     meta: {breadCrumb: "Partner", title: "Partner"},
    //     children: [
    //         { path: 'transaction-list', component: require('@/components/admin/partner/TransactionListComponent.vue').default, meta: { breadCrumb: "Transaction List", title: "Transaction List" } },
    //         { path: 'transaction-detail',component: require('@/components/admin/partner/TransactionDetailComponent.vue').default, meta: { breadCrumb: "Transaction Detail", title: "Transaction Detail"}},
    //         { path: 'bank-deposit', component: require('@/components/admin/partner/BankDepositComponent.vue').default, meta: { breadCrumb: "Bank Deposit", title: "Bank Deposit" } },
    //         { path: 'bill-company', component: require('@/components/admin/partner/PartnerListComponent.vue').default, meta: { breadCrumb: "Partner List", title: "Partner List" } },
    //         { path: 'add-partner',component: require('@/components/admin/partner/AddPartnerComponent.vue').default, meta: { breadCrumb: "Add Partner", title: "Add Partner"}},
    //     ]
    // },

    {
        path: '/setting',
        component: () => import('@/components/admin/uikit/RouterView.vue'),
        meta: {
            breadCrumb: 'Setting',
        },
        children: [
            { path: 'user-list', component: () => import('@/components/admin/setting/UserListComponent.vue'), meta: { breadCrumb: "User List", title: "User List"}},
            { path: 'user-add', component: () => import('@/components/admin/setting/AddUserComponent.vue'), meta: { breadCrumb: "Add User", title: "Add User"}},
            { path: 'channels', component: () => import('@/components/admin/setting/ChannelsComponent.vue'), meta: { breadCrumb: "Channels", title: "Channels"}},
            { path: 'blank-channels', component: () => import('@/components/admin/setting/BlankChannelsComponent.vue'), meta: { breadCrumb: "Channels", title: "Channels"}},
            { path: 'preferences', component: () => import('@/components/admin/setting/PreferencesComponent.vue'), meta: { breadCrumb: "Preferences", title: "Preferences"}},
            { path: 'limits', component: () => import('@/components/admin/setting/LimitsComponent.vue'), meta: { breadCrumb: "Limits", title: "Limits"}},
            { path: 'payouts', component: () => import('@/components/admin/setting/PayoutComponent.vue'), meta: { breadCrumb: "Payouts", title: "Payouts"}},
            { path: 'Profile', component: () => import('@/components/admin/setting/MyProfileComponent.vue'), meta: { breadCrumb: "My Profile", title: "Profile"}},
            { path: 'setup-profile', component: () => import('@/components/admin/setting/SetupProfileComponent.vue'), meta: { breadCrumb: "Set Up Your Profile", title: "Set Up Your Profile"}},
            { path: 'compliance', component: () => import('@/components/admin/setting/ComplianceComponent.vue'), meta: { breadCrumb: "Compliance", title: "Compliance"}},
            { path: 'compliance-requirements', component: () => import('@/components/admin/setting/ComRequirementsComponent.vue'), meta: { breadCrumb: "Registered Sole Proprietorship", title: "Compliance"}},
        ]
    },
    {
        path: '/admin/page-404',
        component: () => import('@/components/admin/notfound/NotFoundComponent.vue'),
        meta: {title: "No Data Found", breadCrumb: "No Data Found"},
    },
    //ui-kit routes
    {
        path: '/admin/uikit',
        component: () => import('@/components/admin/uikit/RouterView.vue'),
        meta: { breadCrumb: 'Ui Kit'},
        children: [
            { path: 'index', component: () => import('@/components/admin/uikit/IndexComponent.vue'), meta: { breadCrumb: "Index", title: "Dashboard"}},
            { path: 'iot', component: () => import('@/components/admin/uikit/IotComponent.vue'),meta: { breadCrumb: "Iot Dashboard", title: "Iot Dashboard"}},
            { path: "app-inbox",component: {render(c) {return c("router-view");}},
                meta: { breadCrumb: "Inbox"},
                children: [{ path: "",component:  () => import('@/components/admin/uikit/InboxComponent.vue'), meta:{ title: "Inbox", },},
                        {path: "compose",component:  () => import('@/components/admin/uikit/ComposeComponent.vue'),meta: { breadCrumb: "Compose", title: "Compose"}},
                ],
            },
            { path: 'app-chat', component: () => import('@/components/admin/uikit/ChatComponent.vue'), meta: { breadCrumb: "Chat", title: "Chat", },},
            { path: 'app-calendar', component: () => import('@/components/admin/uikit/CalendarComponent.vue'), meta: { breadCrumb: "Calendar", title: "Calendar"}},
            { path: 'app-contact-grid', component: () => import('@/components/admin/uikit/ContactGrideComponent.vue'), meta: { breadCrumb: "Contact Card", title: "Contact Card"}},
            { path: 'file-documents', component: () => import('@/components/admin/uikit/DocumentsComponent.vue'),name:'fdocuments', meta: { breadCrumb: "File Documents", title: "File Documents" },},
            { path: 'file-media', component: () => import('@/components/admin/uikit/FileMediaComponent.vue'),name:'fmedia', meta: { breadCrumb: "File Media", title: "File Media" },},
            { path: 'sweet-alert', component: () => import('@/components/admin/uikit/SweetAlertComponent.vue'),name:'sweetalert', meta: { breadCrumb: "Sweet Alert", title: "Sweet Alert" },},
            { path: 'file-images', component: () => import('@/components/admin/uikit/FileImageComponent.vue'),name:'fimages', meta: { breadCrumb: "File Images", title: "File Images" },},
            { path: 'blog-post', component: () => import('@/components/admin/uikit/PostComponent.vue'),name:'blog_post', meta: { breadCrumb: "Blog Post", title: "Blog Post" }},
            { path: 'blog-list', component: () => import('@/components/admin/uikit/ListComponent.vue'),name:'blog_list', meta: { breadCrumb: "Blog List", title: "Blog List" }},
            { path: 'page-lockscreen', component: () => import('@/components/admin/uikit/LockscreenComponent.vue'),name: 'lockscreen', meta: { title: "Lockscreen", layout:'auth' }},
            { path: 'page-404', component: () => import('@/components/admin/uikit/FourZeroFourComponent.vue'),name: '404', meta: { title: "404 Page", layout:'auth' }},
            { path: 'page-403', component: () => import('@/components/admin/uikit/FourZeroThreeComponent.vue'),name: '403', meta: { title: "403 Page", layout:'auth' }},
            { path: 'page-500', component: () => import('@/components/admin/uikit/FiveZeroZeroComponent.vue'),name: '500', meta: { title: "500 Page", layout:'auth' }},
            { path: 'page-503', component: () => import('@/components/admin/uikit/FiveZeroThreeComponent.vue'),name: '503', meta: { title: "503 Page", layout:'auth' }},
            { path: 'blog-details', component: () => import('@/components/admin/uikit/DetailsComponent.vue'),name:'blog_details', meta: { breadCrumb: "Blog Details", title: "Blog Details" },},
            { path: 'ui-typography', component: () => import('@/components/admin/uikit/TypographyComponent.vue'),name:'typography', meta: { breadCrumb: "Typography", title: "Typography" },},
            { path: 'ui-tabs', component: () => import('@/components/admin/uikit/TabsComponent.vue'), meta: { breadCrumb: "Tabs", title: "Tabs" },},
            { path: 'ui-buttons', component: () => import('@/components/admin/uikit/ButtonsComponent.vue'), meta: { breadCrumb: "Buttons", title: "Buttons" },},
            { path: 'ui-bootstrap', component: () => import('@/components/admin/uikit/BootstrapComponent.vue'), meta: { breadCrumb: "Bootstrap UI", title: "Bootstrap UI" },},
            { path: 'ui-icons', component: () => import('@/components/admin/uikit/IconComponent.vue'), meta: { breadCrumb: "Icons", title: "Icons" },},
            { path: 'ui-notifications', component: () => import('@/components/admin/uikit/NotificationComponent.vue'), meta: { breadCrumb: "Notifications", title: "Notifications" },},
            { path: 'ui-colors', component: () => import('@/components/admin/uikit/ColorComponent.vue'), meta: { breadCrumb: "Colors", title: "Colors" },},
            { path: 'ui-listgroup', component: () => import('@/components/admin/uikit/ListGroupComponent.vue'), meta: { breadCrumb: "List Group", title: "List Group" },},
            { path: 'ui-media', component: () => import('@/components/admin/uikit/MediaComponent.vue'), meta: { breadCrumb: "Media Object", title: "Media Object" },},
            { path: 'ui-modals', component:() => import('@/components/admin/uikit/ModalComponent.vue'), meta: { breadCrumb: "Modals", title: "Modals" },},
            { path: 'ui-progressbars', component: () => import('@/components/admin/uikit/ProgressbarComponent.vue'), meta: { breadCrumb: "Progressbar", title: "Progressbar" },},
            { path: 'widgets-data', component: () => import('@/components/admin/uikit/DataComponent.vue'), meta: { breadCrumb: "Widgets Data",title: "Widgets Data" },},
            { path: 'widgets-weather', component: () => import('@/components/admin/uikit/WeatherComponent.vue'), meta: { breadCrumb: "Widgets Weather",title: "Widgets Weather" },},
            { path: 'widgets-blog', component: () => import('@/components/admin/uikit/BlogComponent.vue'), meta: { breadCrumb: "Widgets Blog",title: "Widgets Blog" },},
            { path: 'widgets-ecommerce', component: () => import('@/components/admin/uikit/EcommerceComponent.vue'), meta: { breadCrumb: "Widgets eCommerce",title: "Widgets eCommerce" },},
            { path: 'page-blank', component: () => import('@/components/admin/uikit/BlankComponent.vue'), meta: { breadCrumb: "Page Blank",title: "Page Blank" },},
            { path: 'page-profile', component: () => import('@/components/admin/uikit/ProfileComponent.vue'), meta: { breadCrumb: "User Profile",title: "User Profile" },},
            { path: 'page-profile2', component: () => import('@/components/admin/uikit/ProfiletwoComponent.vue'), meta: { breadCrumb: "User Profile",title: "User Profile v2" },},
            { path: 'page-gallery', component: () => import('@/components/admin/uikit/GalleryComponent.vue'),name:'pgallery', meta: { breadCrumb: "Gallery",title: "Gallery" },},
            { path: 'page-timeline', component: () => import('@/components/admin/uikit/TimelineComponent.vue'),name:'ptimeline', meta: { breadCrumb: "Timeline",title: "Timeline" },},
            //{ path: 'page-pricing', component: PricingComponent, meta: { breadCrumb: "Pricing",title: "Pricing" },},
            { path: 'page-invoices', component: () => import('@/components/admin/uikit/InvoicesComponent.vue'), meta: { breadCrumb: "Invoices",title: "Invoices" },},
            { path: 'page-search-results', component: () => import('@/components/admin/uikit/SearchComponent.vue'), meta: { breadCrumb: "Search Results",title: "Search Results" },},
            { path: 'page-helper-class', component: () => import('@/components/admin/uikit/HelperClassComponent.vue'), meta: { breadCrumb: "Helper Class",title: "Helper Class" },},
            { path: 'page-teams-board', component: () => import('@/components/admin/uikit/TeamsBoardComponent.vue'), meta: { breadCrumb: "Teams Board",title: "Teams Board" },},
            { path: 'page-projects-list', component: () => import('@/components/admin/uikit/ProjectsListComponent.vue'), meta: { breadCrumb: "Projects List",title: "Projects List" },},
            { path: 'page-maintanance', component: () => import('@/components/admin/uikit/MaintananceComponent.vue'),name: 'maintanance', meta: {title: "Maintanance" },},
            { path: 'page-testimonials', component: () => import('@/components/admin/uikit/TestimonialsComponent.vue'), meta: { breadCrumb: "Testimonials",title: "Testimonials" },},
            { path: 'page-faq', component: () => import('@/components/admin/uikit/FaqComponent.vue'), meta: { breadCrumb: "FAQs",title: "FAQs" },},
            { path: 'forms-validation', component: require('@/components/admin/uikit/ValidationComponent.vue').default, meta: { breadCrumb: "Form Validation",title: "Form Validation"  },},
            { path: 'forms-basic', component: () => import('@/components/admin/uikit/BasicFormComponent.vue'), meta: { breadCrumb: "Basic Form Elements",title: "Basic Form Elements"  },},
            { path: 'table-normal', component: () => import('@/components/admin/uikit/NormalComponent.vue'), meta: { breadCrumb: "Table Normal",title: "Table Normal" },},
            { path: 'chart-echarts', component: () => import('@/components/admin/uikit/EChartsComponent.vue'), meta: { breadCrumb: "E-Charts",title: "E-Charts" },},
            { path: 'map-leaflet', component: () => import('@/components/admin/uikit/LeafletComponent.vue'), meta: { breadCrumb: "Leaflet Map",title: "Leaflet Map" },},
        ]
    },
    {
        path: '*',
        redirect: '/page-login'
    }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  base: ''
})

router.afterEach((to) => {
    Vue.nextTick( () => {
        document.title = ':: PayAngel :: '+ to.meta.title;
    });
})
Vue.config.performance = true
Vue.config.productionTip = false
new Vue({
  router:router,
  store,
  render: h => h(App),
}).$mount('#app')