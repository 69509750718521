<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb :breadCrumbIteams="breadCrumbIteams"></bread-crumb>
        </div>

        <!-- Business List-->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card tab-card no-shadow">
                     <!-- Header section-->
                    <div class="header">
                        <h2>{{countryName}}</h2>
                    </div>
                    <div class="body pt-0">
                        <b-tabs content-class="px-0" class="business-tab" nav-class="nav-tabs-new2" :no-nav-style="true">
                            <!-- Payout Details Tab -->
                            <b-tab title="Payout Details" v-if="destination || crossborder ">
                                <b-table 
                                    ref = "payoutTable"
                                    responsive
                                    hover 
                                    outlined
                                    table-class="js-basic-example table-custom mb-0"
                                    head-variant="light"
                                    :items="getPayoutListData" 
                                    :fields="payoutfields"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :sort-direction="sortDirection"
                                    :emptyText="'No data found.'" show-empty>
                                    <template #head()="{label,  field: { key }}">
                                        {{ label }}
                                        <!-- Custom icons -->
                                        <template>
                                            <i v-if="sortBy !== key" class=""></i>
                                            <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                            <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                        </template>  
                                    </template>
                                    
                                    <template #cell(action)="row">
                                        <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                            <b-dropdown-item @click="openLimitModal(row)">Limit</b-dropdown-item>  
                                            <b-dropdown-item @click="openDiscountModal(row)">Discount</b-dropdown-item>
                                        </b-dropdown>
                                    </template>
                                </b-table>

                                <!-- Limit Modal -->
                                <b-modal id="manage_limit_modal" title="Limit" hide-footer no-stacking no-close-on-backdrop>
                                    <!--  @shown="resetForm" -->
                                    <div class="modal-area">
                                        <ValidationObserver v-slot="{ passes }">
                                            <ul class="list-unstyled mb-0 payment-single-transfer">
                                                <form id="funding_option" @submit.prevent="passes(transferLimitSubmit)" autocomplete="off">
                                                    <li>  
                                                        <ValidationProvider name="minlimit" rules="required" v-slot="{ errors}">   
                                                            <div class="form-group" :class="{'errors': errors.length && !transferLimit.min || errors[0],'success':transferLimit.min && !errors[0]} ">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="transferLimit.min" class="form-control" id="min" placeholder="Minimum Transfer Limit">
                                                                    <label for="min" class="control-label">Minimum Transfer Limit</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !transferLimit.min || errors[0] "></i>
                                                                    <i class="fa fa-check" v-if="transferLimit.min && !errors[0]"></i>
                                                                </span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </li>
                                                    <li>  
                                                        <ValidationProvider name="maxlimit" rules="required" v-slot="{ errors}">   
                                                            <div class="form-group" :class="{'errors': errors.length && !transferLimit.max || errors[0],'success':transferLimit.max && !errors[0]} ">
                                                                <span class="float-label">
                                                                    <input type="text" class="form-control" id="max" v-model="transferLimit.max" placeholder="Maximum Transfer Limit">
                                                                    <label for="max" class="control-label">Maximum Transfer Limit</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !transferLimit.max || errors[0] "></i>
                                                                    <i class="fa fa-check" v-if="transferLimit.max && !errors[0]"></i>
                                                                </span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </li>
                                                    <li>
                                                        <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                            <b-button type="submit" variant="primary btn-mw" size="lg" v-if="payoutoptionid==''" >Update </b-button>
                                                            <b-button v-if="payoutoptionid!=''" type="submit" variant="primary btn-mw" size="lg">Update </b-button>
                                                            <b-button @click="$bvModal.hide('manage_limit_modal')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                                        </div>
                                                    </li>
                                                
                                                </form>
                                            </ul>
                                        </ValidationObserver>
                                    </div>
                                </b-modal> 
                                
                                <!-- Discount Modal -->
                                <b-modal id="manage_discount_modal" title="Discount" hide-footer no-stacking no-close-on-backdrop>
                                    <!--  @shown="resetForm" -->
                                    <div class="modal-area">
                                        <ValidationObserver v-slot="{ passes }">
                                            <ul class="list-unstyled mb-0 payment-single-transfer">
                                                <form id="funding_option" @submit.prevent="passes(transferDiscountSubmit)" autocomplete="off">
                                                    <li>  
                                                        <ValidationProvider name="discount_amount" rules="required" v-slot="{ errors}">   
                                                            <div class="form-group" :class="{'errors': errors.length && !transferDiscount.discount_amount || errors[0],'success':transferDiscount.discount_amount && !errors[0]} ">
                                                                <span class="float-label">
                                                                    <b-input-group class="mb-3">
                                                                        <template #prepend>
                                                                            <b-dropdown variant="outline-secondary" menu-class="border-0 shadow">
                                                                                <template #button-content>
                                                                                    {{ transferDiscount.discount_type }}
                                                                                </template>
                                                                                <b-dropdown-item v-for="(type,index) in transferDiscount.discountType" :key="index"  @click="activefbanktype(index)" :active="index == transferDiscount.discount_index">
                                                                                    {{ type.name }}
                                                                                </b-dropdown-item>
                                                                            </b-dropdown>
                                                                        </template>
                                                                        <b-form-input v-model="transferDiscount.discount_amount" placeholder="Enter Value"></b-form-input>
                                                                        <i class="fa fa-times" v-if="errors.length && !transferDiscount.discount_amount || errors[0] "></i>
                                                                        <i class="fa fa-check" v-if="transferDiscount.discount_amount && !errors[0]"></i>
                                                                    </b-input-group>
                                                                </span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </li>
                                                    <li>
                                                        <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                            <b-button type="submit" variant="primary btn-mw" size="lg" v-if="payoutoptionid==''" >Update </b-button>
                                                            <b-button v-if="payoutoptionid!=''" type="submit" variant="primary btn-mw" size="lg">Update </b-button>
                                                            <b-button @click="$bvModal.hide('manage_discount_modal')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                                        </div>
                                                    </li>
                                                </form>
                                            </ul>
                                        </ValidationObserver>
                                    </div>
                                </b-modal> 
                            </b-tab>
                            
                            <!-- Payment Details Tab -->
                            <b-tab title="Payment Details" v-if="domestic || crossborder ">
                                <b-table 
                                    ref = "paymentTable"
                                    responsive
                                    hover 
                                    outlined
                                    table-class="js-basic-example table-custom mb-0"
                                    head-variant="light"
                                    :items="getPaymentListData" 
                                    :fields="paymentfields"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :sort-direction="sortDirection"
                                    :emptyText="'No data found.'" show-empty>
                                    <template #head()="{label,  field: { key }}">
                                        {{ label }}
                                        <!-- Custom icons -->
                                        <template>
                                            <i v-if="sortBy !== key" class=""></i>
                                            <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                            <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                        </template>  
                                    </template>
                                    
                                    <template #cell(action)>
                                        <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                            <b-dropdown-item>Limit</b-dropdown-item> 
                                            <b-dropdown-item>Discount</b-dropdown-item>
                                        </b-dropdown>
                                    </template>
                                </b-table>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import { ValidationObserver, ValidationProvider } from "vee-validate";
// import Multiselect from 'vue-multiselect'
// const AddPartner = require('@/components/admin/country/AddPartnerModal.vue').default
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
// const supportedCurrency = require('@/components/admin/country/SupportedCurrency.vue').default
import shared from "@/shared.js";
// const { VueTelInput } = require('vue-tel-input')
// import parsePhoneNumber from 'libphonenumber-js'

export default {
    name:'CountryDetailComponent',
    components: {
        BreadCrumb,
        ValidationObserver,
        ValidationProvider,
        // Multiselect,
        // VueTelInput,
        // 'add-partner':AddPartner,
        'main-loader':LoaderComponent,
        // 'supported-currency':supportedCurrency
    },data (){
        return{
            isVisibleFilter: false,
            readonly : true,

            mobileInputError:'',


            breadCrumbIteams:[
                // {
                //     title:'Country',
                //     links:'/country/list'
                // },
                // {
                //     title:this.countryName,
                //     links:''
                // }
            ],
                  

            payoutfields: [
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'Action'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],
            paymentfields: [
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix'},
                // { key: 'action', label: 'Action'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            payoutitems:[],
            paymentitems:[],
            countryName:'',

            totalRows: 0,
            currentPage: 1,
            currentPageJump: 1,
            perPage: 10,
            pageOptions: [10 , 25, 50, { value: 100, text: 100 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            searchText: '',
            filterBy: '',
            filterValue: '',

            apiErrorCode: '',
            apiErrorMsg : '',

            domestic : false,
            destination : false,
            crossborder : false,

            transferLimit: {
                min: "",
                max: ""
            },
            transferDiscount: {
                discount_amount: "",
                discount_index: 0,
                discount_type: 'Flat',
                discountType:[
                    {
                        name:'Flat'
                    },
                    {
                        name:'Percentage'
                    }
                ]
            },

            payoutnameid:'',
            payoutname:'',
            payoutoptionid: ''
        }
    },methods: {  
        mobileInputValidate(phoneObject){
            this.mobileInputError = phoneObject.valid
        },
        onlyNumber(event) {
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if (keyCode < 48 || keyCode > 57) { 
                event.preventDefault();
            }
            if (/^\W$/.test(event.key)) {
                event.preventDefault();
            }
        },

        async countryDetail(countryID){
            this.$refs.loader.show();
            // console.log(countryID);
            try {
                const response = await this.$http.get(this.$baseurl+"countries/"+countryID);
                // JSON responses are automatically parsed.
                // console.log(response);
                this.countryName = response.data.countryname;

                this.breadCrumbIteams=[
                    {
                        title:'Country',
                        links:'/country/list'
                    },
                    {
                        title:this.countryName,
                        links:''
                    }
                ];
                this.$refs.loader.hide();
                // this.countryIsocode = response.data.isoCode;
                
                // this.primaryMobileNumber = {
                //     mode: "auto",
                //     defaultCountry:'gh',//response.data.isoCode,
                //     autoFormat:false,
                //     dropdownOptions:{
                //         showFlags: true,
                //         disabled:true,
                //         showDialCodeInSelection:true
                //     }, 
                // },
                // this.readonly = false;

            } catch (error) {
                console.log(error);
                this.$refs.loader.hide();
            }
        },
        async getPayoutListData() {
            try {
                this.payoutitems = [];
                const response = await this.$http.get(this.$baseurl+"payout/options/name",{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") 
                    },
                });
                if(response.data.code === '0100-300') {
                    if(response.data.details.length > 0) {
                        response.data.details.forEach(element => {
                            let item = {
                                id:element.id,
                                name:element.name,
                                action: ''
                            };
                            this.payoutitems.push(item);
                        })
                        return this.payoutitems;
                    }
                }
            }catch(error) {
                console.log(error);
            }
        },

        async getPaymentListData() {
            try {
                this.paymentitems = [];
                const response = await this.$http.get(this.$baseurl+"payment/options/name",{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") 
                    },
                });
                if(response.data.code === '0100-100') {
                    if(response.data.details.length > 0) {
                        response.data.details.forEach(element => {
                            let item = {
                                id:element.id,
                                name:element.name,
                                action: ''
                            };
                            this.paymentitems.push(item);
                        })
                        return this.paymentitems;
                    }
                }
            }catch(error) {
                console.log(error);
            }
        },

        openLimitModal(row){
            console.log(row);
            this.payoutnameid=row.item.id;
            this.payoutname=row.item.name;
            this.getPayoutDetails('limit');
            // this.$bvModal.show('manage_limit_modal');
        },

        openDiscountModal(row){
            // console.log(row);
            this.payoutnameid=row.item.id;
            this.payoutname=row.item.name;
            this.getPayoutDetails('discount');
            // this.$bvModal.show('manage_discount_modal');
        },

        //set active prefix
        activefbanktype(menuIndex){
            this.transferDiscount.discount_index = menuIndex;
            this.transferDiscount.discount_type = this.transferDiscount.discountType[menuIndex].name;
        },

        async getPayoutDetails(type){
            try {
                let paramData = {
                    payoutnameid : this.payoutnameid,
                    createdby : sessionStorage.getItem('customerID'),
                    countryid : sessionStorage.getItem('countryId')
                }
                const response = await this.$http.get(this.$standapp+"customer/payment/payout_options/",{
                    params:paramData,
                    headers: {
                        Authorization: 'Basic YmRkMTc5NTY5MmQ1NTBiMWIwY2M0YmUzZmJkN2MyMTY6ZTczYTdhNDhjNDYzNDM5Nzg1MTUyNTI4MWE4NzdkMWY=',//"Bearer " + sessionStorage.getItem("jwtToken"),
                    }
                });
                // console.log(response);
                if(response.data.code === '0130-000') {
                    const payoutOptionDetails = response.data.data[0];
                    // console.log(payoutOptionDetails);
                    this.payoutoptionid = payoutOptionDetails?.payoutoptionid ? payoutOptionDetails.payoutoptionid : '';
                    this.transferLimit.min = payoutOptionDetails?.minlimit ? payoutOptionDetails.minlimit : '';
                    this.transferLimit.max = payoutOptionDetails?.maxlimit ? payoutOptionDetails.maxlimit : '';
                    this.transferDiscount.discount_amount = payoutOptionDetails?.discount ? payoutOptionDetails.discount : '';
                    this.transferDiscount.discount_index = 0;
                    this.transferDiscount.discount_type = payoutOptionDetails?.discount_type ? payoutOptionDetails.discount_type : 'Flat';
                }
                // console.log(this.payoutoptionid, this.transferLimit.min, this.transferLimit.max, this.transferDiscount.discount_amount, this.transferDiscount.discount_type);
                // console.log(this.payoutoptionid != '');
                if(type === 'limit'){
                    this.$bvModal.show('manage_limit_modal');
                }else if(type === 'discount'){
                    this.$bvModal.show('manage_discount_modal');
                }
            }catch(error) {
                console.log(error);
            }
        },

        transferLimitSubmit(){
            this.$refs.loader.show();
            if(this.payoutoptionid){
                this.updateLimit();
            }else{
                let addData = {
                    payoutnameid : this.payoutnameid,
                    name : this.payoutname,
                    createdby : sessionStorage.getItem('customerID'),
                    minlimit : this.transferLimit.min,
                    maxlimit : this.transferLimit.max,
                    countryid : sessionStorage.getItem('countryId')
                }
                // console.log(addData);
                this.$http.post(this.$standapp+'customer/payment/payout_options/', addData,{
                    headers: {
                            Authorization: 'Basic YmRkMTc5NTY5MmQ1NTBiMWIwY2M0YmUzZmJkN2MyMTY6ZTczYTdhNDhjNDYzNDM5Nzg1MTUyNTI4MWE4NzdkMWY=',//"Bearer " + sessionStorage.getItem("jwtToken"),
                        }
                })
                .then((res) => {
                    if(res.data.code === "0130-000"){
                        shared.toastrSuccess('Successfully Added');
                        this.$bvModal.hide('manage_limit_modal');
                    }
                    this.$refs.loader.hide();
                })
                .catch((error) => {
                    this.apiErrorCode = error.response.data.code;
                    this.$refs.loader.hide();
                });
            }            
        },
        updateLimit(){
            // console.log('update limit');
            let updateData = {
                payoutoptionid: this.payoutoptionid,
                payoutnameid : this.payoutnameid,
                name : this.payoutname,
                minlimit : this.transferLimit.min,
                maxlimit : this.transferLimit.max,
                countryid : sessionStorage.getItem('countryId')
            }
            // console.log(addData);
            this.$http.put(this.$standapp+'customer/payment/payout_options/', updateData,{
                headers: {
                        Authorization: 'Basic YmRkMTc5NTY5MmQ1NTBiMWIwY2M0YmUzZmJkN2MyMTY6ZTczYTdhNDhjNDYzNDM5Nzg1MTUyNTI4MWE4NzdkMWY=',//"Bearer " + sessionStorage.getItem("jwtToken"),
                    }
            })
            .then((res) => {
                if(res.data.code === "0130-000"){
                    shared.toastrSuccess('Successfully Updated');
                    this.$bvModal.hide('manage_limit_modal');
                }
                this.$refs.loader.hide();
            })
            .catch((error) => {
                this.apiErrorCode = error.response.data.code;
                this.$refs.loader.hide();
            });
        },

        transferDiscountSubmit(){
            this.$refs.loader.show();
            if(this.payoutoptionid){
                this.updateDiscount();
            }else{
                let addData = {
                    payoutnameid : this.payoutnameid,
                    name : this.payoutname,
                    createdby : sessionStorage.getItem('customerID'),
                    discount_type : this.transferDiscount.discount_type,
                    discount : this.transferDiscount.discount_amount,
                    countryid : sessionStorage.getItem('countryId')
                }
                // console.log(addData);
                this.$http.post(this.$standapp+'customer/payment/payout_options/', addData,{
                    headers: {
                            Authorization: 'Basic YmRkMTc5NTY5MmQ1NTBiMWIwY2M0YmUzZmJkN2MyMTY6ZTczYTdhNDhjNDYzNDM5Nzg1MTUyNTI4MWE4NzdkMWY=',//"Bearer " + sessionStorage.getItem("jwtToken"),
                        }
                })
                .then((res) => {
                    if(res.data.code === "0130-000"){
                        shared.toastrSuccess('Successfully Added');
                        this.$bvModal.hide('manage_discount_modal');
                    }
                    this.$refs.loader.hide();
                })
                .catch((error) => {
                    this.apiErrorCode = error.response.data.code;
                    this.$refs.loader.hide();
                });
            }
        },
        updateDiscount(){
            // console.log('update discount');
            let updateData = {
                payoutoptionid: this.payoutoptionid,
                payoutnameid : this.payoutnameid,
                name : this.payoutname,
                discount_type : this.transferDiscount.discount_type,
                discount : this.transferDiscount.discount_amount,
                countryid : sessionStorage.getItem('countryId')
            }
            // console.log(addData);
            this.$http.put(this.$standapp+'customer/payment/payout_options/', updateData,{
                headers: {
                        Authorization: 'Basic YmRkMTc5NTY5MmQ1NTBiMWIwY2M0YmUzZmJkN2MyMTY6ZTczYTdhNDhjNDYzNDM5Nzg1MTUyNTI4MWE4NzdkMWY=',//"Bearer " + sessionStorage.getItem("jwtToken"),
                    }
            })
            .then((res) => {
                if(res.data.code === "0130-000"){
                    shared.toastrSuccess('Successfully Updated');
                    this.$bvModal.hide('manage_discount_modal');
                }
                this.$refs.loader.hide();
            })
            .catch((error) => {
                this.apiErrorCode = error.response.data.code;
                this.$refs.loader.hide();
            });
        }
    },mounted() {
        if(sessionStorage.getItem("countryId")){
            this.countryDetail(sessionStorage.getItem("countryId"));
            // this.contactDetailData(sessionStorage.getItem("countryId"));
            this.domestic = JSON.parse(sessionStorage.getItem("countryDomestic"));
            this.destination = JSON.parse(sessionStorage.getItem("countryDestination"));
            this.crossborder = JSON.parse(sessionStorage.getItem("countryCrossborder"));
            // console.log(typeof this.domestic, typeof this.destination, typeof this.crossborder);
        }        
        this.$root.$refs.CountryDetailComponent = this;
        
        // this.bankDetailData();
        // this.getPayoutListData();
        // this.getPaymentListData();
    },directives: {
        'click-outside': {
            bind: function(el, binding) {
                // Define Handler and cache it on the element
                const bubble = binding.modifiers.bubble
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e)
                    }
                }
                el.__vueClickOutside__ = handler

                // add Event Listeners
                document.addEventListener('click', handler)
            },
        }
    }
}
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>