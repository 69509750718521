<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <ValidationObserver v-slot="{ passes }">
                        <div class="header">
                            <h2>Edit Customer <span style="color:red" class="pull-right"><b>LOGIN TO CUSTOMER ACCOUNT</b></span></h2>
                            <!-- <span style="color:red" class="pull-right"><b>LOGIN TO CUSTOMER ACCOUNT</b></span> -->
                        </div>
                        <div class="body">
                            <form @submit.prevent="passes(userSubmitData)" id="basic-form">
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <ul class="list-unstyled">
                                            <li class="mb-2">
                                                <div class="row">
                                                    <div class="col-md-5"><strong> Registered Date Time:</strong></div>
                                                    <div class="col-md-7"><span>2022-08-19 06:37:47</span></div>
                                                </div>
                                            </li>
                                            <li class="mb-2">
                                                <div class="row">
                                                    <div class="col-md-5"><strong> IP Address: </strong></div>
                                                    <div class="col-md-7"><span>27.4.207.98</span></div>
                                                </div>
                                            </li>
                                            <li class="mb-2">
                                                <div class="row">
                                                    <div class="col-md-5"><strong> Remitter Id With Remit One: </strong></div>
                                                    <div class="col-md-7"><span>10840</span></div>
                                                </div>
                                            </li>
                                            <li class="mb-2">
                                                <div class="row">
                                                    <div class="col-md-5"><strong> Payangel ID: </strong></div>
                                                    <div class="col-md-7"><span>AF114517</span><span class="pull-right" style="color:red"><b>See Beneficiaries</b></span></div>
                                                    <!-- <b>No Beneficiaries</b> -->
                                                </div>
                                            </li>
                                            <li class="mb-2">
                                                <div class="row">
                                                    <div class="col-md-5"><strong>ID3 Authentication ID(Registered):</strong></div>
                                                    <div class="col-md-7"><span>86f6fbbf-75ec-4043-b9a6-deb426d69eab</span></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <ul class="list-unstyled">
                                            <li class="mb-2">
                                                <div class="row">
                                                    <div class="col-md-5"><strong>ID3 Authentication ID(Upgraded): </strong></div>
                                                    <div class="col-md-7"><span>N/A</span></div>
                                                </div>
                                            </li>
                                            <li class="mb-2">
                                                <div class="row">
                                                    <div class="col-md-5"><strong>ID3 BandText1:</strong></div>
                                                    <div class="col-md-7"><span>REFER</span></div>
                                                </div>
                                            </li>
                                            <li class="mb-2">
                                                <div class="row">
                                                    <div class="col-md-5"><strong>ID3 BandText2:</strong></div>
                                                    <div class="col-md-7"><span></span></div>
                                                </div>
                                            </li>
                                            <li class="mb-2">
                                                <div class="row">
                                                    <div class="col-md-5"><strong>ID3 Score(Registered):</strong></div>
                                                    <div class="col-md-7"><span>2</span></div>
                                                </div>
                                            </li>
                                            <li class="mb-2">
                                                <div class="row">
                                                    <div class="col-md-5"><strong>ID3 Score(Upgraded):</strong></div>
                                                    <div class="col-md-7"><span>0</span></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card card-top-line">
                                    <div class="header d-flex align-items-center justify-content-between">
                                        <h2>Personal Information</h2>
                                    </div>
                                    <div class="body">
                                        <div class="edit-form">
                                            <div class="row">
                                                <div class="col-sm-6 col-12">
                                                    <ValidationProvider name="salutation" rules="required" v-slot="{ errors }">
                                                        <div class="form-group form-select single-multiselect select-option-custom" :class="{'errors': errors[0],'success':user.salutation && !errors[0]}">
                                                            <span class="float-label">
                                                            <label for="salutation">Select Salutation*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="user.salutation"
                                                                    :show-labels="false" 
                                                                    label="name"
                                                                    :options="salutation_options">
                                                                </multiselect>
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="user.salutation && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="first_name" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !user.first_name,'success':user.first_name}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="user.first_name" class="form-control" id="first_name" placeholder="First Name*">
                                                                <label for="first_name" class="control-label">First Name*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !user.first_name"></i>
                                                                <i class="fa fa-check" v-if="user.first_name"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <div class="form-group">
                                                        <span class="float-label">
                                                            <input type="text" class="form-control" id="middle_name" v-model="user.middle_name" placeholder="Middle Name">
                                                            <label for="middle_name" class="control-label">Middle Name</label>
                                                            <div class="line"></div>
                                                        </span>
                                                    </div>
                                                    <ValidationProvider name="last_name" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !user.last_name,'success':user.last_name}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="user.last_name" class="form-control" id="last_name" placeholder="Last Name*">
                                                                <label for="last_name" class="control-label">Last Name*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !user.last_name"></i>
                                                                <i class="fa fa-check" v-if="user.last_name"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <div class="">
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <label for="gender" class="control-label">Gender: *</label>
                                                            </div>
                                                            <div class="col-md-9">
                                                                <label class="fancy-radio"><input name="gender2" v-model="user.gender" value="Male" type="radio" :checked="user.gender == 'Male'"><span><i></i>Male</span></label>
                                                                <label class="fancy-radio"><input name="gender2" v-model="user.gender" value="Female" type="radio" :checked="user.gender == 'Female'"><span><i></i>Female</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ValidationProvider name="contact_email" rules="required|email" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !user.contact_email || errors[0],'success':user.contact_email && !errors[0]}">
                                                            <span class="float-label">
                                                                <input type="email" v-model="user.contact_email" class="form-control" name="email1" id="contact_email" placeholder="Email*">
                                                                <label for="contact_email" class="control-label">Email*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !user.contact_email || errors[0] "></i>
                                                                <i class="fa fa-check" v-if="user.contact_email && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="username" rules="required|email" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !user.username || errors[0],'success':user.username && !errors[0]}">
                                                            <span class="float-label">
                                                                <input type="email" v-model="user.username" class="form-control" name="email1" id="username" placeholder="Username*">
                                                                <label for="username" class="control-label">Username*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !user.username || errors[0] "></i>
                                                                <i class="fa fa-check" v-if="user.username && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <div class="form-group datepicker">
                                                        <span class="float-label">
                                                            <b-input-group>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text">Date of Birth</span>
                                                                    <span class="input-group-text" @click="$refs.birthdate.showCalendar()"><i class="icon-calendar"></i></span>
                                                                </div>
                                                                <datepicker class="form-control" ref="birthdate" v-model="user.dob" :bootstrap-styling="false" placeholder="" :typeable="true"></datepicker>
                                                            </b-input-group>    
                                                        </span>
                                                    </div>
                                                    <ValidationProvider name="remitergroup" rules="required" v-slot="{ errors }">
                                                        <div class="form-group form-select single-multiselect select-option-custom" :class="{'errors': errors[0],'success':user.remitergroup && !errors[0]}">
                                                            <span class="float-label">
                                                            <label for="remitergroup">Remitter Group*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="user.remitergroup"
                                                                    :show-labels="false" 
                                                                    :options="remitgroupOption">
                                                                </multiselect>
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="user.salutation && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <div class="form-group">
                                                        <label>Account Status:</label>
                                                        <br/>
                                                        <label v-for="status in accstatus_options" :key="status">
                                                            <input
                                                            type="checkbox"
                                                            name="checkbox"
                                                            :value="status">
                                                            <span>{{ status }}</span>&nbsp;&nbsp;
                                                        </label>
                                                        <!-- <label class="fancy-checkbox" :v-for="status in accstatus_options">
                                                            <input type="checkbox" name="checkbox">
                                                            <span>{{status}}</span>
                                                        </label>
                                                        <label class="fancy-checkbox">
                                                            <input type="checkbox" name="checkbox">
                                                            <span>Option 2</span>
                                                        </label>
                                                        <label class="fancy-checkbox">
                                                            <input type="checkbox" name="checkbox">
                                                            <span>Option 3</span>
                                                        </label> -->
                                                        <p id="error-checkbox"></p>
                                                    </div>
                                                    <div class="form-group">
                                                        <span class="float-label">
                                                            <textarea class="form-control" id="address1" v-model="user.address1" placeholder="Address Line 1*"></textarea>
                                                            <label for="address1" class="control-label">Address Line 1*</label>
                                                            <div class="line"></div>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-12">
                                                    <div class="form-group">
                                                        <span class="float-label">
                                                            <textarea class="form-control" id="address2" v-model="user.address2" placeholder="Address Line 2"></textarea>
                                                            <label for="address2" class="control-label">Address Line 2</label>
                                                            <div class="line"></div>
                                                        </span>
                                                    </div>
                                                    <div class="form-group">
                                                        <span class="float-label">
                                                            <textarea class="form-control" id="address3" v-model="user.address3" placeholder="Address Line 3"></textarea>
                                                            <label for="address3" class="control-label">Address Line 3</label>
                                                            <div class="line"></div>
                                                        </span>
                                                    </div>
                                                    <div class="form-group">
                                                        <span class="float-label">
                                                            <input type="text" class="form-control" id="state" v-model="user.state" placeholder="Middle Name">
                                                            <label for="state" class="control-label">State</label>
                                                            <div class="line"></div>
                                                        </span>
                                                    </div>
                                                    <ValidationProvider name="city" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !user.city,'success':user.city}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="user.city" class="form-control" id="city" placeholder="City*">
                                                                <label for="city" class="control-label">City*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !user.city"></i>
                                                                <i class="fa fa-check" v-if="user.city"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="postcode" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !user.postcode,'success':user.postcode}">
                                                            <span class="float-label">
                                                                <input type="text" v-model="user.postcode" class="form-control" id="postcode" placeholder="Postal code*">
                                                                <label for="postcode" class="control-label">Postal code*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !user.postcode"></i>
                                                                <i class="fa fa-check" v-if="user.postcode"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="select_country" rules="required" v-slot="{ errors}">
                                                        <div class="country-dropdown">
                                                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.country && user.country.length != 0  && !errors[0]}">
                                                                <span class="float-label">
                                                                <label for="Country">Country of Residence*</label>
                                                                    <multiselect
                                                                        placeholder=""
                                                                        v-model="user.country" 
                                                                        :show-labels="false" 
                                                                        :options="countrys_options" 
                                                                        :searchable="false" 
                                                                        label="name" 
                                                                        track-by="name"
                                                                        @select="setPrimaryNumberCountryCode">
                                                                        <template slot="option" slot-scope="props">
                                                                            <span :class="props.option.flagClass"></span>
                                                                            <div class="option__desc">
                                                                                <span class="option__title">{{ props.option.name }}</span>
                                                                            </div>
                                                                        </template>
                                                                    </multiselect> 
                                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                    <i class="fa fa-check" v-if="user.country && user.country.length != 0 && !errors[0]"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="countryBirth" rules="required" v-slot="{ errors }">
                                                        <div class="form-group form-select single-multiselect select-option-custom" :class="{'errors': errors[0],'success':user.countryBirth && !errors[0]}">
                                                            <span class="float-label">
                                                            <label for="countryBirth">Country of Birth*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="user.countryBirth"
                                                                    :show-labels="false" 
                                                                    label="name" 
                                                                    :options="countryBirth_options">
                                                                </multiselect>
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="user.countryBirth  && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="sourceCountry" rules="required" v-slot="{ errors }">
                                                        <div class="form-group form-select single-multiselect select-option-custom" :class="{'errors': errors[0],'success':user.sourceCountry && !errors[0]}">
                                                            <span class="float-label">
                                                            <label for="sourceCountry">ID Source Country*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="user.sourceCountry"
                                                                    :show-labels="false" 
                                                                    label="name"
                                                                    :options="sourceCountry_options">
                                                                </multiselect>
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="user.sourceCountry  && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                    <div class="form-group">
                                                        <span class="float-label">
                                                            <input type="text" class="form-control" id="telephone" v-model="user.telephone" placeholder="Telephone">
                                                            <label for="telephone" class="control-label">Telephone</label>
                                                            <div class="line"></div>
                                                        </span>
                                                    </div>

                                                    <!-- Country Code and Mobile Number -->
                                                    <div class="form-group" :class="{'errors': mobileInputError == false,'success':user.mobile_number && mobileInputError}">
                                                        <div class="float-label" v-if="primaryMobileNumber.defaultCountry">
                                                            <label for="primary_number" class="control-label">Mobile*</label>
                                                            <vue-tel-input
                                                                v-model="user.mobile_number"
                                                                v-bind="primaryMobileNumber"
                                                                @validate ="mobileInputValidate"
                                                                ref="telInput"
                                                                @keydown.native="restrictSpecialCharacter($event)">
                                                                <template v-slot:arrow-icon>
                                                                    <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                                                </template>
                                                            </vue-tel-input>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="mobileInputError == false"></i>
                                                            <i class="fa fa-check" v-if="mobileInputError && user.mobile_number"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-top-line">
                                    <div class="header d-flex align-items-center justify-content-between">
                                        <h2>ID And Account Details</h2>
                                    </div>
                                    <div class="body">
                                        <div class="edit-form">
                                            <div class="row">
                                                <div class="col-sm-6 col-12">
                                                    <div class="form-group form-select single-multiselect select-option-custom">
                                                        <span class="float-label">
                                                            <label for="idtype">Id Type</label>
                                                            <multiselect
                                                                placeholder=""
                                                                v-model="user.idtype"
                                                                :show-labels="false" 
                                                                :options="idtype_options">
                                                            </multiselect>
                                                        </span>
                                                    </div>
                                                    <div class="form-group">
                                                        <span class="float-label">
                                                            <input type="text" class="form-control" id="idnumber" v-model="user.idnumber" placeholder="ID Number">
                                                            <label for="idnumber" class="control-label">ID Number</label>
                                                            <div class="line"></div>
                                                        </span>
                                                    </div>
                                                    <div class="form-group">
                                                        <span class="float-label">
                                                            <input type="text" class="form-control" id="iddetails" v-model="user.iddetails" placeholder="ID Details">
                                                            <label for="iddetails" class="control-label">ID Details</label>
                                                            <div class="line"></div>
                                                        </span>
                                                    </div>
                                                    <div class="form-group datepicker">
                                                        <span class="float-label">
                                                            <b-input-group>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text">Id Expiry date</span>
                                                                    <span class="input-group-text" @click="$refs.idexpiry.showCalendar()"><i class="icon-calendar"></i></span>
                                                                </div>
                                                                <datepicker class="form-control" ref="idexpiry" v-model="user.idexpiry" :bootstrap-styling="false" placeholder="" :typeable="true"></datepicker>
                                                            </b-input-group>    
                                                        </span>
                                                    </div>
                                                    <div class="form-group datepicker">
                                                        <span class="float-label">
                                                            <b-input-group>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text">Account Expiry</span>
                                                                    <span class="input-group-text" @click="$refs.accountexpiry.showCalendar()"><i class="icon-calendar"></i></span>
                                                                </div>
                                                                <datepicker class="form-control" ref="accountexpiry" v-model="user.accountexpiry" :bootstrap-styling="false" placeholder="" :typeable="true"></datepicker>
                                                            </b-input-group>    
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-12">
                                                    <div class="">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <label for="remittertype" class="control-label">Remitter Type:</label>
                                                            </div>
                                                            <div class="col-md-8">
                                                                <label class="fancy-radio"><input name="remittertype" value="N/A" type="radio"><span><i></i>N/A</span></label>
                                                                <label class="fancy-radio"><input name="remittertype" value="registered" type="radio" checked><span><i></i>Registered</span></label>
                                                                <label class="fancy-radio"><input name="remittertype" value="nonregistered" type="radio"><span><i></i>Non-Registered</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <label for="remitterstatus" class="control-label">Remitter Status:</label>
                                                            </div>
                                                            <div class="col-md-8">
                                                                <label class="fancy-radio"><input name="remitterstatus" v-model="user.remitterstatus" value="Valid" type="radio" :checked="user.remitterstatus == 'Valid'"><span><i></i>Valid</span></label>
                                                                <label class="fancy-radio"><input name="remitterstatus" v-model="user.remitterstatus" value="Expired" type="radio" :checked="user.remitterstatus == 'Expired'"><span><i></i>Expired</span></label>
                                                                <label class="fancy-radio"><input name="remitterstatus" v-model="user.remitterstatus" value="Blocked" type="radio" :checked="user.remitterstatus == 'Blocked'"><span><i></i>Blocked(Suspected Account?)</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <label for="isverified" class="control-label">Is Verified: *</label>
                                                            </div>
                                                            <div class="col-md-8">
                                                                <label class="fancy-radio"><input name="isverified" value="yes" type="radio"><span><i></i>Yes</span></label>
                                                                <label class="fancy-radio"><input name="isverified" value="no" type="radio" checked><span><i></i>No</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <label for="passcodeverified" class="control-label">Is Passcode Verified: *</label>
                                                            </div>
                                                            <div class="col-md-8">
                                                                <label class="fancy-radio"><input name="passcodeverified" value="yes" type="radio"><span><i></i>Yes</span></label>
                                                                <label class="fancy-radio"><input name="passcodeverified" value="no" type="radio" checked><span><i></i>No</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <label for="resetpassword" class="control-label">Do you want to reset password? : *</label>
                                                            </div>
                                                            <div class="col-md-8">
                                                                <label class="fancy-radio"><input name="resetpassword" value="yes" type="radio" checked><span><i></i>Yes</span></label>
                                                                <label class="fancy-radio"><input name="resetpassword" value="no" type="radio"><span><i></i>No</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <span class="float-label">
                                                            <input type="text" class="form-control" id="password" v-model="user.password" placeholder="Password">
                                                            <label for="password" class="control-label">Password</label>
                                                            <div class="line"></div>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <label for="accountstatus" class="control-label">Account Status: *</label>
                                                            </div>
                                                            <div class="col-md-8">
                                                                <label class="fancy-radio"><input name="accountstatus" value="active" type="radio"><span><i></i>Active</span></label>
                                                                <label class="fancy-radio"><input name="accountstatus" value="inactive" type="radio" checked><span><i></i>Inactive</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card card-top-line">
                                    <div class="header d-flex align-items-center justify-content-between">
                                        <h2>Others Details</h2>
                                    </div>
                                    <div class="body">
                                        <div class="edit-form">
                                            <div class="row">
                                                <div class="col-sm-6 col-12">
                                                    <div>
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <label for="affiliatecode" class="control-label">Affiliate Code: </label>
                                                            </div>
                                                            <div class="col-md-8"><span>N/A</span></div>    
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <label for="referredby" class="control-label">Referred By: </label>
                                                            </div>
                                                            <div class="col-md-8"><span>N/A</span></div>    
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <span class="float-label">
                                                            <textarea class="form-control" id="comments" v-model="user.comments" placeholder="Comments"></textarea>
                                                            <label for="comments" class="control-label">Comments</label>
                                                            <div class="line"></div>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <label for="banktransfer" class="control-label">Bank Transfer Only (Payment Method)?: *</label>
                                                            </div>
                                                            <div class="col-md-8">
                                                                <label class="fancy-radio"><input name="banktransfer" value="yes" type="radio" checked><span><i></i>Yes</span></label>
                                                                <label class="fancy-radio"><input name="banktransfer" value="no" type="radio"><span><i></i>No</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <label for="passcode" class="control-label">Passcode: </label>
                                                            </div>
                                                            <div class="col-md-8"><span></span></div>    
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-12">
                                                    <div>
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <label for="mobilemoney" class="control-label">Mobile Money AutoPay:</label>
                                                            </div>
                                                            <div class="col-md-8">
                                                                <label class="fancy-radio"><input name="mobilemoney" value="active" type="radio"><span><i></i>Active</span></label>
                                                                <label class="fancy-radio"><input name="mobilemoney" value="disable" type="radio" checked><span><i></i>Disable</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <label for="ibdmoney" class="control-label">IBD Money AutoPay:</label>
                                                            </div>
                                                            <div class="col-md-8">
                                                                <label class="fancy-radio"><input name="ibdmoney" value="active" type="radio"><span><i></i>Active</span></label>
                                                                <label class="fancy-radio"><input name="ibdmoney" value="disable" type="radio" checked><span><i></i>Disable</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <label for="superaffiliate" class="control-label">Is Super Affiliate:</label>
                                                            </div>
                                                            <div class="col-md-8">
                                                                <label class="fancy-radio"><input name="superaffiliate" value="active" type="radio"><span><i></i>Active</span></label>
                                                                <label class="fancy-radio"><input name="superaffiliate" value="disable" type="radio" checked><span><i></i>Disable</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group datepicker">
                                                        <span class="float-label">
                                                            <b-input-group>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text">Super Affiliate Start Date:</span>
                                                                    <span class="input-group-text" @click="$refs.affiliatedate.showCalendar()"><i class="icon-calendar"></i></span>
                                                                </div>
                                                                <datepicker class="form-control" ref="affiliatedate" v-model="user.affiliatesdate" :bootstrap-styling="false" placeholder="" :typeable="true"></datepicker>
                                                            </b-input-group>    
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <label for="channel" class="control-label">Channel: </label>
                                                            </div>
                                                            <div class="col-md-8"><span>ANDROID</span></div>    
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <label for="notifycustomer" class="control-label">Do you want to Notify Customer to update ID? : *</label>
                                                            </div>
                                                            <div class="col-md-8">
                                                                <label class="fancy-radio"><input name="notifycustomer" value="no" type="radio"><span><i></i>No</span></label>
                                                                <label class="fancy-radio"><input name="notifycustomer" value="yes" type="radio" checked><span><i></i>Yes</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-md-row justify-content-center">
                                    <b-button variant="secondary btn-mw" size="lg" @click="cancel()">Cancel</b-button>
                                    <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Submit</b-button>
                                </div>
                            </form>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const { VueTelInput } = require('vue-tel-input')
import parsePhoneNumber from 'libphonenumber-js';
const LoaderComponent = require("@/components/admin/loader/LoaderComponent.vue").default;
export default {
    name: "EditCustomerComponent",
    components: {
        ValidationObserver,
        ValidationProvider,
        VueTelInput,
        Multiselect: () => import('vue-multiselect'),
        Datepicker: () => import('vuejs-datepicker'),
        BreadCrumb : () => import('@/components/BreadCrumb.vue'),
        "main-loader": LoaderComponent,
    },
    data: () => ({
        isAllChecked:false,
        salutation_options: [
            { name: 'Mr.', value: 'Mr'},
            { name: 'Mrs.', value: 'Mrs'},
            { name: 'Ms.', value: 'Ms'},
            { name: 'Miss', value: 'Miss'},
            { name: 'Dr.', value: 'Dr'},
            { name: 'Prof.', value: 'Prof'},
            { name: 'Revd', value: 'Revd'},
            { name: 'Pastor', value: 'Pastor'},
            { name: 'Hon.', value: 'Hon'},
            { name: 'Capt.', value: 'Capt'},
            { name: 'Bishop', value: 'Bishop'},
            { name: 'Col.', value: 'Col'},
            { name: 'Flt.Lieut.', value: 'Flt.Lieut'},
            { name: 'Other', value: 'Other'},
            // 'Mr.', 'Mrs.', 'Ms.', 'Miss', 'Dr.', 'Prof.', 'Revd', 'Pastor', 'Hon.','Capt.','Bishop','Col.','Flt.Lieut.','Other'
        ],
        remitgroupOption:['Red','Basic','Bronze','Silver','Silver-PLUS'],
        accstatus_options:['Red','Basic','Bronze','Silver','Silver-PLUS','Gold','Platinum','TEST','Dormant','Not Transacted','GREY - Unvalidated Profiles','Expired','Hibernate','Bank Transferred Profiles','Grey'],
        idtype_options:['Passport','Driving License','National Insurance','National ID','Utility Bill','Foreigner Identification Card','National Identification Card','HealthCard','Visa','Other'],
        user: {
            salutation:'',
            first_name:'',
            middle_name:'',
            last_name:'',
            gender:'',
            contact_email:'',
            username:'',
            dob:'',
            remitergroup:'',
            address1:'',
            address2:'',
            address3:'',
            state:'',
            city:'',
            postcode:'',
            country: null,
            countryBirth: null,
            sourceCountry: null,
            telephone:'',
            mobile_number:'',
            idtype:'',
            idnumber:'',
            iddetails:'',
            idexpiry:'',
            accountexpiry:'',
            remittertype:'',
            remitterstatus:'',
            isverified:'',
            passcodeverified:'',
            resetpassword:'',
            password:'',
            accountstatus:'',
            affiliatecode:'',
            refferedby:'',
            comments:'',
            banktransfer:'',
            passcode:'',
            mobilemoney:'',
            ibdmoney:'',
            superaffiliate:'',
            affiliatesdate:'',
            channel:'',
            notifycustomer:''
        },

        countrys_options: [],
        countryBirth_options: [],
        sourceCountry_options: [],
        mobileInputError:'',
        primaryMobileNumber:{
            mode: "auto",
            defaultCountry:"",
            autoFormat:false,
            dropdownOptions:{
                showFlags: true,
                disabled:true,
                showDialCodeInSelection:true
            }, 
        },
    }),
    methods: {
        userSubmitData() {
            if(typeof(this.mobileInputError) == 'undefined'){
                this.mobileInputError = false
            }
            if(this.mobileInputError){
                console.log('submit');
            }
        },

        cancel(){
            this.$router.push({ path: '/customer/list' })
        },

        restrictSpecialCharacter(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        },

        setPrimaryNumberCountryCode(defaultCountry) {
            this.$refs.telInput.choose(defaultCountry.isocode);
            // sessionStorage.setItem('countryCode',defaultCountry.primaryNumberCode);
        },
        
        mobileInputValidate(phoneObject){
            this.mobileInputError = phoneObject.valid
        },

        displayText(count){
            if (count === 0){
                return ''
            }else if (count === 1){
                return this.user.country[0].name
            }else if(count == this.countrys_options.length){
                this.isAllChecked = true;
                return count + ' Countrys Selected.'
            }else {
                this.isAllChecked = false;
                return  count + ' Countrys Selected.'
            }
        },

        selectAllChanged() {
            if (this.isAllSelected) {
                this.isAllChecked = false;
                this.user.country = [];
                this.countrys_options.map((data)=>{
                    data.isChecked = false
                });
            }else{
                this.isAllChecked = true;
                this.user.country = this.countrys_options.slice();
                this.countrys_options.map((data)=>{
                    data.isChecked = true
                });
            }
        },

        isCheckedInput (actionName) {
            this.countrys_options[actionName.id].isChecked = !this.countrys_options[actionName.id].isChecked
        },

        async allCountry(){
            try {
                const response = await this.$http.get(this.$baseurl+"countries");
                //JSON responses are automatically parsed.
                response.data.map((item) => {
                    let countryData = {
                        id:item.countryid, 
                        isocode: item.isocode, 
                        code: item.mobilecountrycode, 
                        name: item.countryname, 
                        flagClass:"flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
                    }
                    this.countrys_options.push(countryData);
                    this.countryBirth_options.push(countryData);
                })
            } catch (error) {
                console.log(error);
                // shared.toastrError(error);
            }
        },

        async sourceCountry(){
            try {
                const response = await this.$http.get(this.$baseurl+"countries/source");
                //JSON responses are automatically parsed.
                response.data.details.map((item) => {
                    let countryData = {
                        id:item.countryid, 
                        isocode: item.isocode, 
                        code: item.mobilecountrycode, 
                        name: item.countryname, 
                        flagClass:"flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
                    }
                    this.sourceCountry_options.push(countryData);
                })
            } catch (error) {
                console.log(error);
                // shared.toastrError(error);
            }
        },

        async customerDetail(customerId){
            try {
                this.$refs.loader.show();
                const response = await this.$http.get(this.$apiV2+"user/customer/?customer_id="+customerId,{
                    // headers: {
                    //         'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    //     },
                });
                if(response.data.code == '0131-000'){
                    const customerdetails = response.data.data[0];
                    const businessDetails = customerdetails.business;
                    const id3Details = customerdetails.id3_details;
                    const addressDetail = customerdetails.address;
                    // console.log(customerdetails);

                    let title = null;
                    this.salutation_options.forEach(element => {
                        if(element.value == customerdetails.title){
                            title = {name:element.name,value:customerdetails.title};
                        }
                    });

                    let countryofresidence = null;
                    let mobilenumber = '';
                    this.countrys_options.forEach(element => {
                        if(element.id == businessDetails.countryofresidence){
                            countryofresidence = {
                                id:element.id, 
                                isocode: element.isocode, 
                                code: element.code, 
                                name: element.name, 
                                flagClass:"flag-icon flag-icon-"+element.isocode.toLowerCase()+" fi-xl fi-round",
                            };

                            this.primaryMobileNumber = {
                                mode: "auto",
                                defaultCountry:element.isocode,
                                autoFormat:false,
                                dropdownOptions:{
                                    showFlags: true,
                                    disabled:true,
                                    showDialCodeInSelection:true
                                }, 
                            };
                            
                            if(customerdetails.mobile){
                                const phoneNumber = parsePhoneNumber(customerdetails.mobile, element.isocode);
                                mobilenumber = phoneNumber.nationalNumber;
                            }
                        }
                    });
                    let countryofbirth = null;
                    this.countrys_options.forEach(element => {
                        if(element.id == customerdetails.countryofbirth){
                            countryofbirth = {
                                id:element.id, 
                                isocode: element.isocode, 
                                code: element.code, 
                                name: element.name, 
                                flagClass:"flag-icon flag-icon-"+element.isocode.toLowerCase()+" fi-xl fi-round",
                            };
                        }
                    });
                    
                    
                    this.user.salutation = title;
                    this.user.first_name=customerdetails.firstname;
                    this.user.middle_name=customerdetails.middlename;
                    this.user.last_name=customerdetails.lastname;
                    this.user.gender=customerdetails.gender;
                    this.user.contact_email=customerdetails.email;
                    this.user.username=customerdetails.email;
                    this.user.dob=customerdetails.dob;
                    this.user.remitergroup=id3Details.accountgroup ? id3Details.accountgroup : '';
                    this.user.address1=addressDetail.addressline1 ? addressDetail.addressline1 : '';
                    this.user.address2=addressDetail.addressline2 ? addressDetail.addressline2 : '';
                    this.user.address3=addressDetail.addressline3 ? addressDetail.addressline3 : '';
                    this.user.state=addressDetail.state ? addressDetail.state : '';
                    this.user.city=addressDetail.city ? addressDetail.city : '';
                    this.user.postcode=addressDetail.postalcode ? addressDetail.postalcode : '';
                    this.user.country= countryofresidence,
                    this.user.countryBirth= countryofbirth;
                    this.user.sourceCountry= null;
                    this.user.telephone=customerdetails.phone;
                    this.user.mobile_number=mobilenumber;
                    this.user.idtype=id3Details.idtype;
                    this.user.idnumber=id3Details.idnumber;
                    this.user.iddetails='';
                    this.user.idexpiry=id3Details.idexpirydate;
                    this.user.accountexpiry='';
                    this.user.remittertype='';
                    this.user.remitterstatus=id3Details.remitstatus;
                    this.user.isverified='';
                    this.user.passcodeverified='';
                    this.user.resetpassword='';
                    this.user.password='';
                    this.user.accountstatus='';
                    this.user.affiliatecode=customerdetails.referralcode;
                    this.user.refferedby=customerdetails.referredby;
                    this.user.comments='';
                    this.user.banktransfer='';
                    this.user.passcode='';
                    this.user.mobilemoney='';
                    this.user.ibdmoney='';
                    this.user.superaffiliate='';
                    this.user.affiliatesdate='';
                    this.user.channel='';
                    this.user.notifycustomer='';
                    // console.log(this.user.remitterstatus);

                    this.$refs.loader.hide();
                }
            } catch (error) {
                console.log(error);
                this.$refs.loader.hide();
            }
        }
    },computed: {
        isAllSelected: function() {
            return  (this.user.country.length === this.countrys_options.length)
        }
    },mounted(){
        this.allCountry();
        this.sourceCountry();
        if(sessionStorage.getItem('editcustomerID')){
            this.customerDetail(sessionStorage.getItem('editcustomerID'));
        }
    }
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>