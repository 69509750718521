<template>
    <div class="right-content mx-auto my-auto">
        <div class="header pb-3 text-center">
            <p class="lead">Login to your account</p>
        </div>
        <div class="body">
            <ValidationObserver v-slot="{ passes }">
                <form class="form-auth-small" @submit.prevent="passes(loginSubmit)" id="login">
                    <!-- Email -->
                    <ValidationProvider name="signin_email" rules="required|email" v-slot="{ errors}">
                        <div class="form-group" :class="{'errors': errors.length && !loginformdata.signin_email || errors[0],'success':loginformdata.signin_email && !errors[0], 'mb-0':apiErrorCode == 'E0004-001'}">
                            <span class="float-label">
                                <input type="email" v-model="loginformdata.signin_email" class="form-control" name="email1" id="signin_email" placeholder="Email*">
                                <label for="signin_email" class="control-label">Email*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !loginformdata.signin_email || errors[0] "></i>
                                <i class="fa fa-check" v-if="loginformdata.signin_email && !errors[0]"></i>
                            </span>
                            <span class="text-danger" v-if="errors[0]">Please enter a valid Email Address</span>
                        </div>
                    </ValidationProvider>
                    <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'E0004-001'">{{apiErrorMessage}}</span>
                    <ValidationProvider name="password" rules="required" v-slot="{ errors}">
                        <div class="form-group set-password" :class="{'errors': errors.length && !loginformdata.passwords,'success':loginformdata.passwords, 'mb-0':apiErrorCode == 'V0004-001' || apiErrorCode == 'E0004-002'}">
                            <span class="float-label">
                                <input :type="loginformdata.password_type" class="form-control" id="signin-password" v-model="loginformdata.passwords" placeholder="Password*">
                                <!-- <input type="password" v-model="loginformdata.passwords" class="form-control" id="signin-password" placeholder="Password*"> -->
                                <label for="signin-password" class="control-label">Password*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !loginformdata.passwords"></i>
                                <i class="fa fa-check" v-if="loginformdata.passwords"></i>
                                <i class="fa fa-eye" :class="loginformdata.password_type == 'password' ? 'grey' : 'success'" aria-hidden="true" @click="showPassword()"></i>
                            </span>
                        </div>
                    </ValidationProvider>
                    <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0004-001' || apiErrorCode == 'E0004-002'">{{apiErrorMessage}}</span>                        
                    <!-- <div class="form-group clearfix"><label class="fancy-checkbox element-left"><input type="checkbox"><span>Remember me</span></label></div> -->
                    <!-- <button type="submit" class="btn btn-primary btn-lg btn-block">LOGIN</button> -->

                    <button-spinner class="btn btn-primary btn-lg btn-block"  v-bind="{isLoading}" :disabled="isLoading"> LOGIN</button-spinner>
                    <div class="bottom">
                        <span class="helper-text m-b-10"><i class="fa fa-lock"></i> <router-link to="/forgot-password">Forgot password?</router-link></span>
                        
                    </div>
                    <!-- <div class="bottom"><span class="helper-text m-b-10"><i class="fa fa-lock"></i> <router-link to="/authentication/page-forgot-password">Forgot password?</router-link></span><span>Don't have an account? <router-link to="/authentication/page-register">Register</router-link></span></div> -->
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider } = require("vee-validate")
import VueButtonSpinner from 'vue-button-spinner';
import shared from "@/shared.js";
export default {
    name:'LoginV2Component',
    components: {
        ValidationObserver,
        ValidationProvider,
        'button-spinner':VueButtonSpinner,
    },methods: {
        
        loginSubmit: function() {
            this.isLoading = true;
            let loginData = {
                email: this.loginformdata.signin_email,
                password: this.loginformdata.passwords
            }
            
            this.$http.post(this.$baseurl+'business/auth/login', loginData)
            .then((res) => {
                this.isLoading = false;
                //Perform Success Action
                if(res.data.code == '0004-000') {
                    sessionStorage.setItem("userName", res.data.user.firstname+' '+res.data.user.lastname);
                    sessionStorage.setItem("jwtToken", res.data.token);
                    sessionStorage.setItem("isBranchUser", res.data.user.branch?'1':'0');
                    sessionStorage.setItem("businessID", res.data.user.businessid);
                    sessionStorage.setItem("customerID", res.data.user.customerid ? res.data.user.customerid : '');
                    // console.log(res);
                    this.$router.push({ path: '/dashboard-v1'})
                    this.$store.dispatch('registration/clearBusiness');
                    this.$store.dispatch('personalInfo/clearPersonal');
                    this.$store.dispatch('contactDetail/clearContact');
                   
                } else if(res.data.code == '0004-001') {
                    sessionStorage.setItem("jwtToken", res.data.token);
                     shared.toastrError(res.data.message);
                     this.$router.push({ path: '/set-password' })
                } else {
                    //this.$swal('Error!', res.data.message, 'error', 'Close');
                    shared.toastrError(res.data.message);
                }
            })
            .catch((error) => {
                this.isLoading = false;
                this.status = false;
                this.apiErrorCode = error.response.data.code;
                if(this.apiErrorCode == "V0004-001") {
                    this.apiErrorMessage = error.response.data.detail;
                } else if(this.apiErrorCode == "E0004-003") {
                    this.$store.dispatch('contactDetail/addContact', loginData);
                    sessionStorage.setItem("businessUserEmail", this.loginformdata.signin_email);
                    this.$router.push({ path: '/standapp-otp' })
                } else {
                    this.apiErrorMessage = error.response.data.message;
                }
            });

            
            // setTimeout(() => {
			// 	this.isLoading = false
            //     this.$router.push({ path: '/dashboard-v1'})
			// }, 2000)
           
        },
        showPassword() {
            this.loginformdata.password_type = this.loginformdata.password_type === 'password' ? 'text' : 'password';
        }

    },
    data(){
        return{
            loginformdata:{
                signin_email:'',
                passwords:'',
                password_type: 'password',
            },
            isLoading: false,
            status: '',
            apiErrorCode : '',
            apiErrorMessage : ''

        }
    }
}
</script>

